@import 'color/colors';

.is-copyable {
  &:hover {
    cursor: url('./cursor-clipboard.png'), auto;
    border-color: $dark-50;
  }
  &:active {
    border-color: $border--active;
  }
}
