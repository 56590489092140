@import 'color/colors';

.loadingComponent {
  align-self: center;
  text-align: center;
  margin: 1em;
  font-size: 42px;
  color: $text--dark;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingComponent__loading {
  text-align: center;
  color: $text--dark;
  display: block;
  padding: 0;
  margin: 0;
}

.loadingComponent--error {
  font-size: 21px;
  color: $dark-80;
  p {
    margin: 4px 0;
  }
}

.loadingComponent__reload {
  margin: 4px 0;
  color: $text--dark;
  &:hover {
    color: $link--light;
  }
}
