@import 'navigation/navigation';
@import 'color/colors';

.new-user {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 20px;
  h2 {
    font-size: 1.25em;
    margin-top: 0;
  }
  &__form {
    h4 {
      svg {
        color: $mint;
        margin-right: 4px;
      }
    }
    display: flex;
    flex-direction: column;
    button {
      margin: 5px auto;
    }
  }
  @media (max-width: 825px) {
    padding: 10px;
  }
}
