@import 'color/colors';

.file-uploader-content-preview {
  height: 280px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &--multi {
    width: fit-content;
    align-items: flex-start;
  }
}

.file-uploader-error {
  height: 25px;
  margin-top: 5px;
  color: $red;
}

.file-uploader__content-uploader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  &--hidden-footer .bcu-footer {
    position: absolute;
    height: 0;
    width: 0;
    margin: 0;
    overflow: hidden;
    .bcu-footer-left {
      width: 0;
      overflow: hidden;
    }
  }
}

.file-uploader__actions {
  display: flex;
  justify-content: flex-end;
}

.file-uploader__primary-action {
  color: $text--dark-1;
  font-size: 12px;
  background: none;
  outline: none;
  border: none;
  text-decoration: underline;
  margin-left: 8px;
  margin-top: 4px;
  cursor: pointer;
  &.align-right {
    align-self: flex-end;
  }
  &:hover {
    color: $new-primary--light;
  }
}

.file-uploader__remove-upload {
  font-size: 12px;
  color: $orange;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 4px 8px 4px 2px;
  margin-top: 4px;
  text-decoration: underline;
  svg {
    margin-right: 4px;
  }
  &:hover {
    color: $orange--light;
  }
}
