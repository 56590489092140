// @mixin font--primary-light {
//   font-family: 'Source Sans Pro', sans-serif;
//   font-style: normal;
//   font-weight: 300;
// }

@mixin font--primary-regular {
  font: unset;
  font-family: 'SF Pro Display', -apple-system, 'BlinkMacSystemFont', 'Open Sans',
    'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: $text--dark;
}

// @mixin font--primary-semibold {
//   font-family: 'Source Sans Pro', sans-serif;
//   font-style: normal;
//   font-weight: 600;
// }

// @mixin font--secondary-regular {
//   font-family: 'Lora', serif;
//   font-style: normal;
//   font-weight: normal;
// }
