@import 'navigation/navigation';
@import 'color/colors';

.dashboard__container {
  width: 100%;
  display: flex;
  flex: 1;
}

.dashboard__main {
  margin-top: $topbar-height;
  display: flex;
  flex-direction: column;
  background-color: $workbench--background;
  flex: 1;
  overflow-x: hidden;
  margin-bottom: 20px;
  &.warning {
    margin-top: $topbar-height + 40px;
  }
}

// Children View Styles

.dashboard__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  align-items: flex-start;
  width: 100%;
  overflow-x: auto;
  padding: 0 0 0 100px;
  @media (max-width: 1440px) {
    padding: 0 0 0 80px;
  }
  @media (max-width: 1080px) {
    padding: 0 0 0 60px;
  }
  @media (max-width: 876px) {
    padding: 0 0 0 40px;
  }
  @media (max-width: 768px) {
    padding: 0 0 0 40px;
  }
}

.dashboard__empty {
  .myBtn--link {
    font-size: inherit;
  }
}

.dashboard__inner {
  padding: 0 10px 20px 0;
  max-width: 1080px;
  width: 100%;
  @media (max-width: 1280px) {
    width: fit-content;
  }
}

.dashboard__cabinet {
  padding: 0 10px 20px 0;
  max-width: 1080px;
  width: 80vw;
  @media (max-width: 1440px) {
    width: 1080px;
  }
  @media (max-width: 1280px) {
    width: 80vw;
    min-width: 800px;
  }
}

.dashboard__search {
  align-items: center;
  color: $dark-75;
  display: flex;
  height: $topbar-height - 2px;
  position: fixed;
  top: 0;
  width: 440px;

  z-index: 6;
  input {
    outline: none;
    height: 38px;
    border-radius: 15px;
    padding-right: 40px;
    padding-left: 40px;
    width: 100%;
    border: none;
    background-color: #f0f0f0;
  }
  &Icon {
    position: absolute;
    margin-left: 12px;
    height: 100%;
    text-align: center;
    svg {
      color: $text--placeholder;
    }
  }
  &Clear {
    margin: 28px 8px;
    font-size: 1.1em;
    color: $text--placeholder;
    cursor: pointer;
    position: absolute;
    right: 8px;
    &:hover {
      color: $primary;
      font-weight: bolder;
    }
  }

  @media (max-width: 1200px) {
    width: 400px;
  }
  @media (max-width: 1080px) {
    width: 340px;
  }
  @media (max-width: 876px) {
    width: 220px;
  }
  @media (max-width: 576px) {
    width: 200px;
  }
}
