@import 'color/colors';

.styled-text-field {
  &__container {
    display: flex;
    flex-direction: column;
    margin: 16px 0;
    max-width: 600px;
    width: 100%;
  }
  &__wrapper {
    background-color: $white;
    border-radius: 10px;
    border: thin solid $text--light;
    display: inline-block;
    height: 52px;
    position: relative;
    width: 100%;
    &--copyable {
      cursor: url('./cursor-clipboard.png'), auto;
      .styled-text-field {
        cursor: inherit;
        &__label {
          cursor: inherit;
        }
      }
      &:hover {
        border-color: $dark-50;
      }
      &:active {
        border-color: $border--active;
      }
    }
    &--error {
      border-color: $red;
      .styled-text-field__label {
        color: $red;
      }
    }
    &--hidden {
      position: absolute;
      height: 0;
      width: 0;
      margin-bottom: 0;
      overflow: hidden;
    }
    &--padded-top {
      .styled-text-field {
        padding-top: 4px;
        &__label {
          font-size: 14px;
          &--active {
            top: -14px;
            font-size: 12px;
          }
        }
      }
    }
    &--disabled {
      background-color: #eee;
      border-color: $border--disabled;
      .styled-text-field {
        padding-top: 12px;
        &__label--active {
          color: $text__label--disabled;
          top: 6px;
          background: transparent;
        }
      }
    }
    &:focus-within {
      border-color: $border--active;
    }
  }
  &__label {
    @include text--input-label;
    margin-right: 16px;
    align-items: center;
    background: transparent;
    display: flex;
    font-style: italic;
    height: 50px;
    border-radius: 10px;
    left: 16px;
    position: absolute;
    top: 0px;
    cursor: text;
    transition: all 0.2s ease-in-out;
    z-index: 2;
    &--active {
      left: 14px;
      cursor: default;
      color: $text__label;
      background: $white;
      font-weight: 500;
      font-size: 12px;
      font-style: normal;
      height: unset;
      top: -8px;
      padding: 0 2px;
    }
    span {
      display: contents;
    }
  }
  @include text--input;
  border-radius: 10px;
  border: none;
  height: 100%;
  outline: none;
  padding: 0 16px 0 16px;
  position: relative;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: visible;
}
