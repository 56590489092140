@import 'color/colors';
@import 'navigation/navigation';

.notices {
  position: fixed;
  align-self: center;
  bottom: 5vh;
  display: flex;
  flex-direction: column;
  transition: all 0.1s;
  z-index: $toast-notice-z-index;
}

.notice__close {
  font-size: 15px;
  color: $primary--light;
  transition: all 0.1s linear;
  align-self: flex-start;
  margin: -10px -8px 0 0;
  &:hover {
    cursor: pointer;
    color: $primary;
    font-weight: bold;
  }
}

.notice {
  min-width: 225px;
  padding: 15px;
  vertical-align: middle;
  color: $notice--text;
  background-color: $mint--light-background;
  border-radius: 3px;
  display: flex;
  margin: 5px;
  align-items: center;
  opacity: 1;
  transition: 1s all;
  display: flex;
  box-shadow: -3px 3px 3px 1px #dae0e359;
  justify-content: space-between;
  &.hide {
    opacity: 0;
  }
  &.error {
    background-color: $red--light;
    color: $white;
  }
  &.warning {
    background-color: $orange--light;
    color: $white;
  }
}
