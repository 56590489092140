@import 'color/colors';
@import 'navigation/navigation';

.sidebar {
  &__container {
    background-color: $bg--nav;
    // box-shadow: 2px 0px 5px 0px $light;
    min-width: $sidebar-width;
    width: $sidebar-width;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $border;
    margin-right: 4px;
    z-index: $sidebar-z-index + 1;
    transition: 0.2s;

    &.is-overlay {
      position: relative;
      transform: translateX(0);
      top: 0px;
      padding: 0;
      z-index: $sidebar-z-index + 2;
      box-shadow: none;
      border-right-color: $border--dark;
      animation: animateRight 0.3s;
      &:focus {
        outline: none;
      }
      .sidebar__nav {
        border-top-color: $border--dark;
      }
    }
    @keyframes animateRight {
      from {
        transform: translateX(-100%);
      }
      to {
        transform: translateX(0);
      }
    }
  }

  &__logo {
    height: $topbar-height - 1px;
    // border-bottom: thin solid $border--light-1;
    display: flex;
    align-items: center;
    padding-left: 40px;
    width: 100%;
    overflow: hidden;
    transition: 0.2s;
    position: sticky;
    top: 0;
    cursor: pointer;
    &:hover {
      img {
        color: $new-primary;
        filter: brightness(0) invert(67%) sepia(66%) saturate(5284%) hue-rotate(161deg)
          brightness(95%) contrast(85%);
      }
    }
    &.isDisabled {
      pointer-events: none;
    }
    img {
      transition: width 0.2s, height 0.1s, margin 0.1s;
      color: $white;
      width: 100px;
    }
  }

  &__arrow {
    z-index: $sidebar-z-index + 1;
    position: fixed;
    top: 0;
    left: $sidebar-width - 39px;
    background-color: inherit;
    display: flex;
    height: $topbar-height - 1px;
    padding: 5px 10px;
    align-items: center;
    color: $white;
    font-size: 20px;
    transition: 0.1s linear;
    cursor: pointer;
    border: none;
    &.is-overlay {
      left: $sidebar-width - 42px;
      width: 40px;
      font-size: 26px;
      z-index: $sidebar-z-index + 3;
      color: $text--light;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 18px;
      }
    }
    &:hover {
      cursor: pointer;
      color: $new-primary;
    }
    &.isDashboard {
      display: none;
      @media (max-height: 700px) {
        display: flex;
      }
    }
    &.isWorkbench {
      display: none;
      @media (max-width: 1200px) {
        display: flex;
      }
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    padding: 0 0 56px;
    min-height: 650px;
    overflow-y: auto;
    overflow-x: hidden;
    position: sticky;
    top: $topbar-height - 1px;
    border-top: thin solid $border--dark;

    &--roomnav {
      top: 60px;
      border-top: none;
    }
    &-goto-transaction {
      height: 60px;
      .myBtn {
        color: $text--dark;
      }
    }
  }

  &__divider {
    width: $sidebar-width - 40px;
    height: 2px;
    background: transparentize($bg--page, 0.8);
    z-index: $sidebar-z-index + 1;
    margin: 15px auto;
    transition: 0.2s;

    @media (max-width: 825px) {
      // display: none;
      margin: 8px auto;
    }
  }

  &__toggle {
    padding-left: 48px;
    transition: 0.2s;
    height: 56px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 650px + $topbar-height;
    cursor: pointer;
    width: $sidebar-width;

    @media (max-height: 780px) {
      top: unset;
      bottom: 0;
    }
    @media (max-height: 700px) {
      &.isDashboard {
        display: none;
      }
    }
    &Icons {
      transition: all 0.2s ease-in-out;
      display: flex;
      svg {
        color: $text--light;
        transition: transform 0.2s ease-in-out 0.2s;
      }
      .left-icon {
        margin-right: 4px;
      }
    }
    h4 {
      margin-left: 12px;
      color: $text--light;
      font-size: 12px;
    }
    &:hover {
      svg {
        color: $new-primary--light;
      }
      h4 {
        color: $new-primary--light;
      }
    }
    &.isCollapsed {
      .left-icon {
        transform: rotate(180deg);
      }
      .right-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__toggleOverlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $bg--nav;
    z-index: 100;
    cursor: pointer;
    transition: 0.2s;
    svg {
      color: $text--light;
      font-size: 26px;
    }
    &:hover {
      svg {
        color: $new-primary;
      }
    }
    &.isDisabled {
      opacity: 0.8;
      &:hover {
        svg {
          color: $text--light;
        }
      }
    }
  }

  &__overlay {
    z-index: $sidebar-z-index + 2;
    background-color: rgba(0, 0, 0, 0.66);
    overflow-y: auto;
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    top: 0;
    left: 0;
    -webkit-overflow-scrolling: touch;
  }

  /** Collapsed Sidebar **/
  &__container.hide {
    width: 60px;
    min-width: 60px;
    .sidebar__logo {
      width: 60px;
      padding-left: 0;
      justify-content: center;
      img {
        width: 32px;
      }
    }
    .sidebar__arrow {
      border-top: none;
      border-bottom: 2px solid $border--dark;
      box-shadow: 2px 0px 5px 0px $light;
      left: 59px;
    }
    .sidebarNavLink {
      padding-left: 0;
      justify-content: center;
      &__text {
        display: none;
      }
      &__company {
        &Wrapper {
          padding-left: 0;
          justify-content: center;
          width: 100%;
          svg {
            display: none;
          }
        }
        &Text {
          display: none;
        }
        &Menu {
          left: 8px;
          &Arrow {
            left: 20px;
          }
        }
      }
    }
    .sidebar__toggle {
      padding-left: 0;
      width: 60px;
      justify-content: center;
      h4 {
        display: none;
      }
    }
    .sidebar__exit {
      left: 100px;
    }
    .workbench-nav__content {
      width: 59px;
      overflow-y: hidden;
    }
  }

  /** Workflow & Teams Nav **/
  &__container.white {
    background-color: $white;
    border-right-color: #eae9e9;
    margin-right: 0;

    .sidebar__logo {
      border-bottom: thin solid $border--dark;
      padding-left: 68px;
      img {
        filter: brightness(0);
      }
      &:hover img {
        filter: brightness(0) invert(67%) sepia(66%) saturate(5284%) hue-rotate(161deg)
          brightness(95%) contrast(85%);
      }
    }

    .sidebar__arrow {
      color: $text--dark-1;
      &:hover {
        color: $new-primary;
      }
    }

    .sidebarNavLink {
      &__icon {
        color: $text--dark-1;
        border-color: $text--dark-1;
      }
      &__text {
        color: $text--dark-1;
      }
      &--selected {
        .sidebarNavLink__icon {
          color: $text--dark;
          font-weight: 800;
        }
        .sidebarNavLink__text {
          color: $text--dark;
          font-weight: 800;
        }
      }
      &:hover {
        .sidebarNavLink__icon {
          color: $new-primary;
          border-color: $new-primary;
        }
        .sidebarNavLink__text {
          color: $new-primary;
        }
      }
      &__company {
        &Wrapper {
          svg {
            color: $text--dark;
          }
          &:hover {
            .sidebarNavLink__companyIcon {
              color: $new-primary;
              border-color: $new-primary;
            }
            .sidebarNavLink__companyText {
              color: $new-primary;
            }
            svg {
              color: $new-primary;
              font-weight: bolder;
            }
          }
        }
        &Icon {
          color: $text--dark;
          border-color: $text--dark-1;
        }
        &Text {
          color: $text--dark;
        }
      }
    }
    .sidebar__toggle {
      svg,
      h4 {
        color: $text--dark-1;
      }
      &:hover {
        svg,
        h4 {
          color: $new-primary--light;
        }
      }
    }
    .project-header {
      background-color: $white;
      &__help {
        a {
          color: $text--dark;
        }
      }
      &__title {
        color: $text--dark;
      }
    }
    .workbench-nav__group-header h2 {
      color: $text--dark-1;
    }
    .workbench-nav__fade {
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 30%, $white 100%);
    }
    .workbench-nav__link {
      color: $text--dark-1;
      svg {
        color: $text--dark-1;
      }
      &-items {
        h4 {
          color: $text--dark-1;
        }
        p {
          color: $text--dark-1;
        }
      }
      &--selected {
        .workbench-nav__link-items {
          h4 {
            color: $text--dark;
          }
        }
      }
      &:hover {
        h4 {
          color: $new-primary;
        }
        svg {
          color: $new-primary;
        }
        .workbench-nav__link-items {
          h4 {
            color: $new-primary;
          }
        }
      }
    }

    &.hide {
      .sidebar__logo {
        padding-left: 0px;
      }
    }
  }
  &__container.dataRoom {
    .sidebar__logo {
      padding-left: 53px;
    }
  }
  &__exit {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 4px;
    height: $topbar-height - 2px;
    font-size: 12px;
    font-weight: normal;
    color: $text--dark;
    background-color: $white;
    position: fixed;
    top: 0;
    left: 60px;
    width: $sidebar-width - 62px;
    outline: none;
    border: none;
    cursor: pointer;

    svg {
      font-size: 12px;
      margin: 0 4px;
    }

    &:hover {
      color: $primary--light;
      text-decoration: underline;
    }
    &.warning {
      color: $orange;
      left: 240px;
      width: unset;
      &:hover {
        color: $orange--light;
      }
    }
  }
}
