@import 'color/colors';

.savvi-tooltip {
  visibility: hidden;
  font-size: 12px;
  min-width: 120px;
  width: max-content;
  background-color: $tooltip;
  color: #fff;
  text-align: center;
  padding: 8px;
  border-radius: 14px;
  position: absolute;
  z-index: 1;

  &--align-left {
    left: -16px;
  }
  &--align-right {
    right: -16px;
  }

  &--position-top {
    bottom: 38px;
  }
  &--position-right {
    top: -8px;
    left: 115%;
  }
  &--position-bottom {
    top: 80%;
  }
  &--position-left {
    top: -8px;
    right: 115%;
  }
  :hover > & {
    visibility: visible;
  }
}
