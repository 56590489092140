@import 'color/colors';

.input-phone-number {
  &__container {
    width: 100%;
    max-width: 600px;
    margin: 16px 0;
    display: flex;
    flex-direction: column;
  }
  &__wrapper {
    background-color: $white;
    border-radius: 10px;
    border: thin solid #afbdc5;
    display: inline-block;
    height: 52px;
    position: relative;
    width: 100%;
    &--error {
      border-color: $red;
      .input-phone-number__label {
        color: $red;
      }
      .input-phone-number > .form-control {
        border: none;
        background-color: transparent;
        &:focus {
          border: none;
          background-color: transparent;
        }
      }
    }
    &--disabled {
      background: #eee;
      border-color: $border--disabled;
      .input-phone-number__label--active {
        top: 2px;
        color: $text__label--disabled;
        background: transparent;
      }
      .input-phone-number {
        padding-top: 12px;
        .flag-dropdown {
          top: 30px;
        }
        &__icon {
          background: #eee;
          padding-top: 4px;
        }
      }
    }
    &--padded-top {
      .input-phone-number {
        padding-top: 4px;
        &__label {
          font-size: 14px;
          &--active {
            top: -14px;
            font-size: 12px;
          }
        }
        .flag-dropdown {
          top: 30px;
        }
      }
      .input-phone-number__label--active {
        background: transparent;
        top: -14px;
      }
    }
  }
  &__label {
    @include text--input-label;
    background: transparent;
    border-radius: 10px;
    font-style: italic;
    left: 40px;
    cursor: text;
    position: absolute;
    top: 0;
    height: 50px;
    display: flex;
    align-items: center;
    transition: left 0.2s ease-in-out, top 0.2s ease-in-out, font-size 0.2s ease-in-out,
      font-style 0.2s ease-in-out, height 0.2s ease-in-out;
    z-index: 2;
    &--active {
      cursor: default;
      color: $text__label;
      top: -11px;
      background: $white;
      height: 20px;
      left: 14px;
      padding: 0 2px;
      font-size: 12px;
      font-style: normal;
    }
    span {
      display: contents;
    }
  }
  &__icon {
    position: absolute;
    left: 16px;
    width: 20px;
    height: 100%;
    top: 0;
    color: rgba(150, 150, 150, 0.5);
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  outline: none;
  border: none;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  & .form-control.invalid-number:focus {
    background-color: transparent;
    border: none;
  }
  & .form-control {
    @include text--input;
    border-radius: 10px;
    border: none;
    padding-left: 48px;
    flex: 1;
    height: 100%;
    &:disabled {
      background-color: #eee;
    }
  }
  .flag-dropdown {
    top: 24px;
    left: 12px;
    bottom: unset;
    border: none;
  }
}
