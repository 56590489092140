@import 'color/colors';
@import 'navigation/navigation';

.outside-form__container {
  width: 100%;
  display: flex;
  flex: 1;
  background-color: $workbench--background;
}

.outside-form__main {
  margin-top: $topbar-height;
  margin-right: $info-sidebar-width;
  display: flex;
  background-color: $workbench--background;
  flex: 1;
  overflow: auto;
  max-width: 1180px;
  padding: 0 60px;
  @media (min-width: 1540px) {
    margin-right: 0;
  }
  @media (max-width: 1280px) {
    margin-right: $info-sidebar-width--sm;
  }
  @media (max-width: 876px) {
    margin-right: $info-sidebar-width--mobile;
  }
  &.no-sidebar {
    max-width: unset;
    margin-right: unset;
    justify-content: center;
    .outside-form__header {
      margin: 0 auto;
    }
    .outside-form__help {
      text-align: center;
      width: 100%;
    }
  }
}

.outside-form__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  align-items: flex-start;
  // margin: 60px auto;
  // background: $white;
  padding: 12px 52px;
  // box-shadow: 0 2px 5px 2px #ccc;
  width: 100%;
  min-height: 480px;
  // overflow-x: auto;
  // overflow-y: hidden;
  padding: 52px 80px 140px;
  max-width: 960px;
  min-width: 720px;
  height: fit-content;
  .workbench-form {
    margin-bottom: 12px;
  }
  @media (min-width: 1780px) {
    padding: 60px 100px 160px;
    max-width: 1000px;
  }
  @media (max-width: 1440px) {
    padding: 40px 40px 100px;
  }

  @media (max-width: 1080px) {
    padding: 40px 20px 100px;
  }
  @media (max-width: 876px) {
    padding: 34px 12px 100px;
  }
}

.outside-form__loader {
  text-align: center;
  font-size: 24px;
  color: $text--dark;
  margin: auto;
  background: $white;
  padding: 40px 52px;
  min-height: 220px;
  box-shadow: 0 2px 5px 2px #ccc;
  min-width: 720px;
  display: flex;
  align-items: center;
  img {
    width: 2em;
  }
}

.outside-form-header {
  margin-bottom: 5px;
  width: 100%;
  h1 {
    @include text--h1;
  }
  h4 {
    @include text--h3;
    margin: 4px 0;
    color: $dark-80;
  }
  &--error {
    text-align: center;
  }
}

.outside-form-section-header {
  margin-bottom: 5px;
}

.outside-form--btn {
  margin-top: 15px;
  // align-self: flex-end;
  box-shadow: -2px 4px 4px 0 #ccc;
}

.outside-form-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.outside-form__bottom {
  bottom: 24px;
  left: 0;
  right: 0;
  position: fixed;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: $white;
  border-top: 1px solid $light-95;
  z-index: $info-bar-z-index - 1;
}

.outside-form__help {
  p {
    margin-bottom: 12px;
  }
}
