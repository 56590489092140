$topbar-height: 60px; //40px
$sub-topbar-height: 50px; //50px
$sidebar-width: 220px;
$review-info-sidebar-width: 240px;
$info-sidebar-width: 340px;
$info-sidebar-width--lg: 380px;
$info-sidebar-width--mobile: 240px;
$info-sidebar-width--sm: 280px;
$bars-z-index: 6;
$sidebar-z-index: 5;
$info-bar-z-index: 4;
$toast-notice-z-index: 10;
