@import 'color/colors';

.company-select {
  &__header {
    text-align: center;
    margin: 15px 0 5px;
    max-width: 1080px;
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    h1 {
      margin: 5px 0;
      color: $text--dark;
      font-size: 24px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        margin-left: 4px;
        font-weight: 400;
        .myBtn {
          color: $text--dark-1;
          padding-left: 0;
          svg {
            position: relative;
            margin-right: 4px;
            top: 2px;
          }
          &:hover {
            color: $new-primary;
          }
        }
      }
    }
    h2 {
      color: $dark-75;
      margin: 8px;
    }
    @media (max-width: 1280px) {
      width: 80vw;
      min-width: 768px;
    }
    @media (max-width: 1080px) {
      width: 90vw;
    }
    @media (max-width: 876px) {
      min-width: unset;
      width: 100%;
    }
    @media (max-width: 768px) {
      padding-left: 0;
    }
    @media (max-width: 576px) {
      padding: 5px 0;
    }
  }
  &__divider {
    width: 100%;
    height: 1px;
    background-color: $border;
  }
  &__list {
    max-width: 1080px;
    margin: 8px 12px 8px 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, auto);
    align-items: center;
    box-shadow: 0px 4px 3px 2px $light-110;
    background-color: $white;
    border-radius: 2px;
    min-width: 800px;
    -webkit-overflow-scrolling: 'touch';
    &.fourCols {
      grid-template-columns: repeat(4, auto);
    }
    &.fiveCols {
      grid-template-columns: repeat(5, auto);
    }
    h4 {
      margin: 0;
      font-size: 14px;
    }

    &-none {
      grid-column-start: 1;
      grid-column-end: 4;
      h2 {
        @include text--h2;
        color: $text--dark-1;
        margin: 12px 0 8px;
      }
    }

    &-headers {
      display: contents;
      h4:first-child {
        border-left: thin solid $border;
        padding-left: 24px;
      }
      h4:last-child {
        border-right: thin solid $border;
      }
    }

    &-header {
      color: $text--light;
      font-size: 14px;
      font-weight: 400;
      padding: 10px;
      min-width: 130px;
      background-color: $white;
      border-bottom: thin solid $border;
      cursor: pointer;
      svg {
        color: $text--light;
        margin-left: 2px;
      }
      &:hover {
        color: $dark-80;
        text-decoration: underline;

        background-color: $light-95;
        svg {
          color: $blue--dark;
        }
      }
    }

    &-row {
      display: contents;
      div:first-child {
        grid-column: 1;
        border-left: thin solid $border;
        padding-left: 24px;
      }
      div:last-child {
        border-right: thin solid $border;
      }
      &:hover > div {
        background-color: $light-90;
        .company-select__list-item-company-name {
          color: $link--light;
          text-decoration: underline;
        }
      }
    }

    &-item {
      margin: 0;
      min-width: 130px;
      padding: 10px;
      // max-width: 208px;
      color: $dark-50;
      display: flex;
      // height: 80px;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      border-bottom: thin solid $border;
      min-height: 68px;
      // max-width: 240px;
      cursor: pointer;
      &-company-name {
        color: $text--dark;
        font-size: 16px;
        font-weight: bold;
      }
      &-label {
        color: $text--dark-2;
        font-size: 14px;
        font-weight: normal;
      }
    }

    /*** Start HIDE SCROLLBAR UNTIL HOVER ***/
    &::-webkit-scrollbar-track {
      visibility: hidden;
    }
    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }
    &:hover {
      &::-webkit-scrollbar-track {
        visibility: visible;
      }
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
    /*** END OF HIDE SCROLLBAR UNTIL HOVER ***/
  }

  &__add-entity {
    font-size: 1.25em;
    padding: 16px;
    cursor: pointer;

    svg {
      color: $blue;
    }

    &:hover {
      color: $dark-75;
      svg {
        color: $blue--dark;
      }
    }
  }
}
