@import 'navigation/navigation';
@import 'color/colors';

.basic__container {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: stretch;
  min-height: 100%;
  background-color: $bg--page;
}

.basic__main {
  margin-top: $topbar-height;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  @media (max-width: 768px) {
    margin-top: $topbar-height + 10px;
  }
}
