@import 'navigation/navigation';
@import 'color/colors';
@import 'color/color-variables';

.OverviewCard {
  box-sizing: border-box;
  background: white;
  transition: all 0.15s;
  display: flex;
  border-radius: 4px;
  box-shadow: 0 4px 10px 0 transparentize(#000000, 0.8);
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  opacity: 1;
  &.disabled {
    background-color: $light-90;
  }
  &.isAssessment {
    border: none;
    box-shadow: none;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
    transition: all 0.25s;
    background: transparent;
    width: 100%;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: inherit;
    padding: 32px 40px 32px 0;
    cursor: pointer;
    border-bottom: thin solid $border;
    &.collapsed {
      border-bottom: none;
    }
    &:hover {
      background-color: $hover;
      .myBtn--icon {
        background-color: $white;
      }
    }

    &.assessment {
      cursor: inherit;
      &:hover {
        background-color: inherit;
      }
    }
    &Actions {
      position: relative;
      .dropdown {
        top: 40px;
        right: -20px;
        width: 160px;
        // &:before {
        //   left: 112px;
        // }
        // &:after {
        //   left: 111px;
        // }
      }
    }
  }

  &__topLabel {
    display: flex;
    flex: 1;
    align-items: center;
    overflow: hidden;
    position: relative;
    padding-left: 36px;
    height: 32px;
    h2 {
      @include text--h1;
      margin: 0 20px 0 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: 825px) {
        margin: 0 0 0 8px;
        font-size: 18px;
      }
    }
  }

  &__iconPlaceholder {
    width: 16px;
  }

  &__toggleCollapse {
    margin-right: 8px;

    svg {
      transition: all 0.2s ease-in-out;
    }
    &:hover svg {
      transform: rotate(-90deg);
    }
    &.isCollapsed {
      svg {
        transform: rotate(-90deg);
      }
      &:hover svg {
        transform: rotate(0);
      }
    }
    &.isFocused {
      &:hover svg {
        transform: rotate(0);
      }
      &.isCollapsed {
        &:hover svg {
          transform: rotate(-90deg);
        }
      }
    }
  }

  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include text--h1;
    font-size: 20px;
    margin: 0;
    @media (max-width: 825px) {
      margin: 0 0 0 8px;
    }
  }

  &__topStatus {
    @include text--h4;
    color: $white;
    background-color: $bg--status;
    height: 28px;
    border-radius: 14px;
    padding: 0 12px;
    line-height: 28px;
    margin-right: 8px;
    &.low {
      background-color: $low;
    }
    &.med {
      background-color: $med;
    }
    &.high {
      background-color: $warning;
    }
  }

  &__subtop {
    display: flex;
    align-items: center;
    padding-left: 40px;
    margin: 4px 0;
    svg {
      font-weight: bolder;
      font-size: 1.2em;
    }
    p {
      margin: 4px;
      color: $dark-75;
    }
  }
  &__error {
    color: $red;
  }

  &__list {
    margin: 0 4px;
    padding: 20px 40px;
    display: flex;
    flex-wrap: wrap;

    &Header {
      @include text--h3;
      color: $text--light;
      height: 40px;
    }

    &ItemRow {
      display: contents;
    }

    &Item {
      justify-self: flex-start;
      min-width: 45%;
      max-width: 45%;
      padding: 0;
      margin-bottom: 32px;

      &.first {
        min-width: 55%;
        max-width: 55%;
      }
      &.isVariantsOpen {
        max-width: unset;
      }
      &Label {
        @include text--body1;
        margin-bottom: 12px;
        span {
          position: relative;
          color: $link;
          cursor: pointer;
          svg {
            margin-left: 4px;
            font-size: 12px;
          }
          .dropdown {
            top: 32px;
            right: -28px;
          }
          &:hover {
            text-decoration: underline;
            color: $link--light;
          }
        }
      }
      &Sublabel {
        @include text--h4;
        span {
          margin-left: 4px;
          color: $text--light;
          &.low {
            color: $low;
          }
          &.med {
            color: $med;
          }
          &.high {
            color: $warning;
          }
        }
      }
      .PDFViewer__action {
        width: 100%;
      }
    }
  }

  &__deliverableToggle {
    color: $mint;
    transition: all 0.25s;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s all linear;
    margin-left: 4px;
    cursor: pointer;

    svg {
      font-weight: bold;
      font-size: 1.5em;
    }
    &.selected {
      transition: all 0.25s;
      background-color: $light-95;
      box-shadow: 3px 2px 3px 0px $light-110;
      color: $mint;
    }
    &:hover {
      background-color: $light-95;
      color: $mint;
      box-shadow: 3px 2px 3px 0px $light-110;
    }
    &:active {
      background-color: $light-95;
      color: $mint;
      box-shadow: none;
    }
  }

  &__listItemTemplates {
    display: flex;
    flex-wrap: wrap;
    border-top: thin solid $border;
    border-bottom: thin solid $border;
    padding-top: 12px;
    &Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 12px;
      h3 {
        @include text--h2;
      }
    }
    &Actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .myBtn {
        margin: 0 8px;
      }
    }
  }
  &__listItemTemplate {
    flex: 50%;
    margin-bottom: 8px;
    padding-bottom: 8px;
    .styled-checkbox {
      margin-bottom: 0;
    }
    .styled-checkbox__label {
      @include text--h3;
      padding-left: 8px;
    }
    .styled-checkbox__input {
      margin-top: 0;
    }
    &Header {
      @include text--h4;
      margin-left: 28px;
      line-height: 18px;
      margin-bottom: 8px;
      p {
        @include text--body2;
        line-height: 18px;
      }
    }
    .PDFViewer {
      margin: 8px 0 8px 28px;
    }
  }
}

.PDFViewer {
  overflow: visible;
  position: relative;
  margin: 0 auto;
  width: 156px;
  height: 80px;

  &__wrapper {
    text-align: center;
    &:hover {
      .PDFViewer__pageControls {
        opacity: 1;
      }
    }
    &.isError {
      .PDFViewer {
        max-height: 180px;
        margin-bottom: 8px;
      }
      &:hover {
        .PDFViewer__action {
          color: $text--dark-1;
        }
        .react-pdf__Document {
          border-color: $border;
        }
      }
      &:hover {
        .PDFViewer {
          overflow: hidden;
        }
      }
    }
    &.isShowOnHover {
      &:hover {
        .PDFViewer {
          overflow: visible;
        }
      }
      .react-pdf__Document {
        max-height: fit-content;

        &:hover {
          position: absolute;
          max-height: fit-content;
          z-index: 3;
          .PDFViewer__pageControls {
            opacity: 1;
          }
        }
      }
    }
    &.isLinkOnHover {
      cursor: pointer;
      &:hover {
        .PDFViewer__action {
          color: $new-primary;
        }
        .react-pdf__Document {
          border-color: $new-primary;
        }
      }
      .react-pdf__Document {
        overflow: hidden;
      }
    }
    &.isError {
      text-align: start;
      margin-top: 0;
      top: unset;
      cursor: default;
    }
    &.isPdfHiding {
      text-align: start;
      .PDFViewer__action {
        text-align: start;
      }
    }
    .bp-ControlsRoot {
      opacity: 0;
      display: none;
    }
    .bp-container {
      min-width: unset;
    }
  }
  &__fade {
    height: 50px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 8px;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, $white);
  }

  &__action {
    @include text--h4;
    text-align: center;
    padding-top: 2px;
    padding-left: 0;
    cursor: pointer;
    margin: 0 auto;
    &:hover {
      color: $new-primary;
    }
  }
  &__label {
    @include text--h4;
    padding-bottom: 4px;
    justify-content: center;
  }
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 800px;
    width: 618px;
    h3 {
      @include text--h3;
      color: $text--dark-1;
    }
    svg {
      margin-right: 4px;
    }
    &.large {
      h3 {
        @include text--h1;
      }
      svg {
        font-size: 24px;
        margin-right: 8px;
      }
    }
  }

  .bcpr-PreviewHeader-content {
    background-color: $bg--page;
  }

  .react-pdf {
    &__Document {
      border: thin solid $border--light;
      background-color: $bg--page;
      overflow: auto;
      height: 100%;
      width: 100%;
      transition: max-height 2s ease-in-out 1s;
      position: relative;
      .react-pdf__message--error {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include text--h3;
        color: $text--dark-1;
      }
      /*** Start HIDE SCROLLBAR UNTIL HOVER ***/
      &::-webkit-scrollbar-track {
        visibility: hidden;
      }
      &::-webkit-scrollbar-thumb {
        visibility: hidden;
      }
      &:hover {
        &::-webkit-scrollbar-track {
          visibility: visible;
        }
        &::-webkit-scrollbar-thumb {
          visibility: visible;
        }
      }
      /*** END OF HIDE SCROLLBAR UNTIL HOVER ***/
    }

    &__Page {
      margin: 8px;
      width: fit-content;
      height: fit-content;
    }
  }

  &__pageControls {
    position: absolute;
    bottom: 5%;
    left: 0;
    right: 0;
    background: white;
    opacity: 0;
    max-width: 96%;
    width: 300px;
    transition: opacity ease-in-out 0.2s;
    box-shadow: 0 4px 10px 0 transparentize(#000000, 0.8);
    border-radius: 4px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $text--dark-1;
    &--visible {
      opacity: 1;
    }

    span {
      font: inherit;
      font-size: 0.8em;
      padding: 0 0.5em;
      flex-grow: 2;
      text-align: center;
    }

    button {
      width: 52px;
      height: 52px;
      background: white;
      border: 0;
      font: inherit;
      font-size: 24px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $text--dark-1;
      svg {
        font-size: 26px;
      }

      &:hover {
        cursor: pointer;
        background-color: #e6e6e6;
        color: $new-primary;
      }
      &:disabled {
        cursor: default;
        background-color: #e6e6e6;
      }

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  &__modal {
    position: static;
    max-width: 90%;
    min-height: 400px;
    max-height: 90%;
    outline: none;
    margin: auto;
    border: none;
    box-shadow: 3px 3px 3px 3px #33373859;
    display: flex;
    flex-direction: column;
    min-width: 400px;
    background-color: $bg--page;
    .PDFViewer:hover {
      .PDFViewer__pageControls {
        opacity: 1;
      }
    }
    &Overlay {
      z-index: 9;
      background-color: rgba(0, 0, 0, 0.66);
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      display: flex;
      overflow-y: auto;
      -webkit-overflow-scrolling-: 'touch';
    }
    &Error {
      @include text--h2;
      margin: 20px 0;
      text-align: center;
      svg {
        color: $warning;
        margin-right: 4px;
      }
    }
    .react-pdf__Document {
      border-top: none;
      .react-pdf__message--error {
        min-width: 400px;
        min-height: 284px;
        margin-top: 60px;
      }
    }
    &Header {
      @include text--h2;
      color: $text--light;
      max-width: 380px;
      margin: 0 auto;
      padding: 8px 24px;
      text-align: center;
    }
    &Exit {
      position: absolute;
      align-self: flex-end;
      right: 16px;
      top: 8px;
      font-size: 1.25em;
      color: $primary;
      cursor: pointer;
      &:hover {
        color: $primary--light;
      }
      &:active {
        color: $primary--dark;
      }
      &:focus {
        color: $primary--dark;
      }
    }
  }
}
