.dd-menu {
  display: inline-block;
  position: relative;

  &.dd-menu-center .dd-menu-items {
    left: 50%;
    transform: translateX(-50%);
  }

  @include dd-position(left);
  @include dd-position(right);
  @include dd-sizes();

  .dd-menu-items {
    position: absolute;
    z-index: $dd-menu-z-index;
    margin: $dd-menu-margin;

    &.dd-items-upwards {
      bottom: 100%;
      margin: $dd-menu-upwards-margin;
    }

    ul,
    ol {
      list-style: none;
      padding: 0;
      margin: 0;
      color: $dd-text-color;
      background-color: $dd-list-color;

      box-shadow: $dd-menu-box-shadow;

      &.dd-items-right li > * {
        text-align: right;
      }

      &.dd-items-left li > * {
        text-align: left;
      }

      &.dd-items-center li > * {
        text-align: center;
      }

      li {
        &:hover:not([role='separator']):not(.separator),
        > *:focus {
          // color: $dd-list-hover-text-color;
          // background-color: $dd-list-hover-color;
          outline: none;
        }

        // > *:not(.dd-item-ignore) {
        //   display: block;
        //   width: auto;
        //   min-width: 100%;
        //   padding: $dd-menu-item-padding;

        //   font-family: $dd-font;
        //   white-space: pre;
        //   color: inherit;
        //   text-decoration: none;
        //   font-size: inherit;
        // }

        > button {
          border: none;
          background: transparent;
        }
      }
    }
  }

  &.dd-menu-inverse {
    .dd-menu-items {
      ul,
      ol {
        color: $dd-inverse-text-color;
        background-color: $dd-inverse-list-color;

        li {
          &:hover:not([role='separator']):not(.separator),
          > *:focus {
            // color: $dd-inverse-list-hover-text-color;
            // background-color: $dd-inverse-list-hover-color;
          }
        }
      }
    }

    [role='separator'],
    .separator {
      background-color: $dd-inverse-separator-color;
    }
  }

  [role='separator'],
  .separator {
    content: '';
    display: block;
    height: $dd-separator-height;
    background-color: $dd-separator-color;
    margin: $dd-separator-margin-top $dd-separator-margin-right
      $dd-separator-margin-bottom $dd-separator-margin-left;
  }

  .dd-items-upwards {
    li.nested-dd-menu > span {
      bottom: 0;
      top: initial;
    }
  }

  li.nested-dd-menu {
    position: relative;

    > span {
      position: absolute;
      top: 0;
    }
  }
}
