@import 'color/colors';

/** *** Main View *** **/
.subscriptions-view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
  min-width: 90vw;
  margin: 10px 0;
  &__workbench {
    margin-top: -40px;
  }

  &__header {
    margin: 10px;
    color: $dark-75;
    font-weight: 300;
    font-size: 24px;
  }

  h2 {
    margin: 0;
    font-size: 20px;
    margin-bottom: 5px;
    padding-left: 8px;
  }
  h4 {
    margin: 10px 0 10px 30px;
    color: $dark-60;
  }

  &__wrapper {
    display: flex;
    margin-top: 50px;
    justify-content: center;
  }
  &__paired {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    border-left: thin solid $primary;
  }

  &__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 15px 0;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    h1 {
      margin: 0;
    }

    @media (max-width: 825px) {
      padding: 5px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: 25px;
    }
  }

  &__addons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: 30px;
    // background-color: $light-90;
    // padding-top: 8px;
    // margin-top: 8px;
    // border-top: 1px solid $light-95;
    @media (max-width: 825px) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-left: 0;
    }
  }

  &__payment {
    margin: 0 auto 20px;
    padding: 20px;
    width: 475px;
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    .form--add-method {
      h3 {
        margin-bottom: 20px;
      }
      .form__button-wrapper {
        button {
          padding: 5px;
        }
      }
    }
  }

  &__finalize {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin: 0 auto 30px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    h4 {
      margin-left: 0;
    }
    p {
      margin-left: 30px;
      color: $dark-75;
      margin-top: -5px;
      font-size: 13px;
    }
    h3 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin: 5px;
      color: $dark-75;
      font-weight: 300;
      .workbench-form__group {
        margin-bottom: 0;
        margin-left: 10px;
        margin-right: 10px;
      }
      button {
        height: 40px;
        width: 130px;
        font-size: 15px;
        font-weight: 500;
        background-color: $primary;
        color: $white;
        border-radius: 3px;
        &:hover {
          background-color: $primary--light;
        }
      }
    }
    .subscriptions-view__discount {
      font-size: 20px;
    }
    .subscriptions-view__discount--locked {
      cursor: disabled;
      background-color: $primary--light-1;
      &:hover {
        background-color: $primary--light-1;
      }
    }

    .subscriptions-view__discount-link {
      color: $blue;
      margin: 0 5px;
      cursor: pointer;
      &:hover {
        color: $blue--dark;
      }
    }
    .subscriptions-view__discount--error {
      color: $red;
    }

    .subscriptions-view__discount--success {
      color: $mint;
    }
  }

  &__carta {
    max-width: 350px;
    margin-left: 30px;
    margin-bottom: 5px;
  }

  &__alert {
    width: 100vw;
    background-color: $mint--light-background-1;
    text-align: center;
    padding: 5px 20px;
    h4 {
      margin: 0 auto;
    }
  }

  &__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    padding: 15px;
    margin-top: 8px;
    border-top: 1px solid $light-95;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    background-color: $light-90;
    .oldbtn {
      box-shadow: -2px 4px 4px 0 $white;
    }
    @media (max-width: 768px) {
      margin: 0 5px 5px;
    }
  }

  &__subscribe {
    p {
      position: absolute;
      margin-top: 10px;
      font-size: 14px;
    }
  }

  &__disclaimer {
    padding: 20px 30px 0;
    background-color: $light-90;
    margin: 0;
  }

  @media (min-width: 1250px) {
    min-width: 1150px;
    min-height: 795px;
  }

  @media (max-width: 825px) {
    width: 95vw;
    margin: 0 auto;
    h2 {
      font-size: 18px;
    }
    h3 {
      font-size: 16px;
    }
  }
}

/** *** features *** **/
.subscriptions-view-features {
  margin: 0 50px 10px;
  overflow: auto;
  width: 978px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: thin solid $light;
  box-shadow: 0px 4px 3px 2px #bdc8cd;
  border-radius: 2px;
  background-color: $white;
  padding: 8px 4px 0;

  @media (max-width: 575px) {
    margin: 10px;
  }
  &__headers {
    z-index: 9; /* this is optional and should be different for every project */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: thin solid $border;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 143px;
  }
  .simple {
    justify-content: center;
  }
  &__header {
    height: 140px;
    width: 175px;
    min-width: 175px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    &:nth-child(2n + 2) {
      background-color: $powder-blue--background;
    }
    h3 {
      margin: 0;
      margin-top: 10px;
      color: $dark-85;
      font-weight: 300;
      font-size: 24px;
    }
    h4 {
      margin: 0;
      font-weight: 300;
      font-size: 22px;
    }
    &.selected {
      background-color: $mint--light-background-1;
    }
  }
  &__billing {
    width: 270px;
    min-width: 270px;
    align-items: stretch;
    padding-left: 8px;
    .subscriptions-view-features__divider {
      width: 200px;
      margin: 10px 0;
    }
    @media (max-width: 768px) {
      width: 210px;
      min-width: 210px;
    }
  }
  &__options {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    // cursor: pointer;
    -webkit-transition: 0.05s all linear;
    transition: 0.05s all linear;
    h4 {
      font-weight: 300;
      font-size: 22px;
      margin: 0;
      margin-left: 10px;
    }
    &--icon {
      border-radius: 15px;
      width: 24px;
      height: 24px;
      border: thin solid $border;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      svg {
        font-size: 16px;
        display: none;
        color: $mint;
      }
    }
    // &:hover {
    //   h4 {
    //     color: $mint;
    //     font-weight: 500;
    //   }
    //   svg {
    //     display: inherit;
    //   }
    // }
    &.selected {
      // h4 {
      //   color: $mint;
      // }
      svg {
        display: inherit;
      }
    }
  }
  &__divider {
    height: 1px;
    background-color: $dark-60;
    width: 50px;
    margin: 10px;
  }
  &__body {
    height: 145px;
    width: 200px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    p {
      font-size: 14px;
      margin: 0 5px;
      font-weight: 300;
      text-align: center;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  }
  &__dropdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    .workbench-form__group {
      margin-bottom: 5px;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      width: 185px;
      max-width: 185px;
    }
  }
  &__btn {
    background-color: $primary;
    height: 30px;
    width: 170px;
    color: $white;
    margin-top: 10px;
    border-width: 0;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      background-color: $primary--light;
      border: thin solid $primary--light;
    }
    &:active {
      border: thin solid $primary--light;
      font-weight: 500;
      outline: none;
    }
    &:focus {
      outline: none;
    }
    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
}

/** SubscriptionsFeaturesSection.js **/
.features-section {
  &__header {
    display: flex;
    align-items: center;
    width: 970px;
    border-bottom: thin solid $border;
    cursor: pointer;
    &-collapsible {
      width: 24px;
      height: 24px;
      min-width: 24px;
      text-align: center;
      margin-left: 5px;
      font-size: 20px;
    }
    &-title {
      width: 200px;
      min-width: 200px;
      h3 {
        margin: 5px 0 5px 5px;
        color: $dark-75;
        font-weight: 400;
      }
    }
    &:hover {
      .features-section__header-collapsible {
        border-radius: 12px;
        background-color: $mint--light-background-1;
      }
      .features-section__header-title {
        h3 {
          color: $dark;
        }
      }
    }
  }
  &__subheader {
    margin-left: 5px;
    color: $dark-75;
    font-weight: 300;
  }
  &__table {
    display: -ms-grid;
    display: grid;
    // -ms-grid-columns: 270px (175px) [4];
    grid-template-columns: 270px repeat(4, 175px);
    @media (max-width: 768px) {
      // -ms-grid-columns: 210px (170px) [4];
      grid-template-columns: 210px repeat(4, 170px);
    }
  }
  &__row-feature {
    min-height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: thin solid $border;
    h4 {
      margin: 3px 0 0;
    }
    .row-feature-icon {
      font-size: 14px;
      width: 35px;
      vertical-align: middle;
      color: $blue;
      margin-top: 8px;
      -webkit-transition: 0.05s all linear;
      transition: 0.05s all linear;
      // &--dot {
      //   font-size: 10px;
      // }
      &--spacer {
        width: 35px;
      }
    }
    &--link {
      &:hover {
        h4 {
          color: $dark;
        }
        .row-feature-icon {
          color: $blue--dark;
        }
      }
    }
    // }
    &-help {
      color: $blue;
      margin: 2px;
      cursor: help;
      &:hover {
        color: $blue--light;
      }
      .dd-menu-items {
        margin: 0 0 0 10px;
        .dd-items-left {
          min-width: 275px;
          padding: 5px 5px 5px 10px;
          li {
            margin: 5px 0;
            font-size: 15px;
            font-weight: 500;
            color: $dark-60;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
          }
        }
      }
    }
  }
  &__item {
    min-height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-bottom: thin solid $border;
    color: $dark-60;
    &--dark {
      background-color: $powder-blue--background;
    }
    &.selected {
      border-bottom: thin solid $mint--light;
      background-color: $mint--light-background-1;
    }
    &.highlighted {
      color: $mint--dark;
    }
  }

  &-modal {
    &__header {
      display: flex;
      justify-content: center;
      width: 100vw;
      margin: 0 auto;
      text-align: center;
      color: $white;
      padding: 10px 0;
      background-color: $primary;
      h2 {
        margin: 0;
      }
      &-help {
        color: $mint;
        margin: 2px;
        cursor: help;
        &:hover {
          color: $blue--light;
        }
        .dd-menu-items {
          margin: 0 0 0 10px;
          .dd-items-left {
            min-width: 275px;
            padding: 5px 5px 5px 10px;
            li {
              margin: 5px 0;
              font-size: 15px;
              font-weight: 500;
              color: $dark-60;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
            }
          }
        }
      }
    }
    &__body {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
    &__description {
      max-width: 350px;
      h3 {
        font-weight: 300;
        color: $dark-75;
      }
    }
    &__ul {
      margin: 0 0 25px;
      color: $primary;
      li {
        h4 {
          color: $dark-60;
          margin: 10px 0;
        }
      }
    }
    &__li-header {
      list-style: none;
      font-weight: 300;
      color: $dark-75;
    }
    &__dashed {
      list-style-type: none;
    }
    &__requirements {
      text-indent: -5px;
    }
    &__exit {
      position: absolute;
      margin-top: 10px;
      right: 15px;
      color: $white;
      font-size: 1.5em;
      cursor: pointer;
    }
  }
}

.subscription-view-paired {
  margin-top: 50px;
  display: flex;
  &__section {
    flex: 1;
    h2 {
      width: 270px;
    }
  }
}
