@import 'color/colors';

.solutions-modal {
  &__header {
    display: flex;
    justify-content: center;
    width: 100vw;
    margin: 0 auto;
    text-align: center;
    color: $white;
    padding: 10px 0;
    background-color: $primary;
    h2 {
      margin: 0;
    }
    &-help {
      color: $mint;
      margin: 2px;
      cursor: help;
      &:hover {
        color: $blue--light;
      }
      .dd-menu-items {
        margin: 0 0 0 10px;
        .dd-items-left {
          min-width: 275px;
          padding: 5px 5px 5px 10px;
          li {
            margin: 5px 0;
            font-size: 15px;
            font-weight: 500;
            color: $dark-60;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
          }
        }
      }
    }
    @media (max-width: 768px) {
      position: absolute;
    }
  }
  &__single-col {
    flex-direction: column;
    h3 {
      margin-bottom: 0;
    }
  }
  &__body {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      margin-top: 50px;
      overflow: auto;
    }
  }
  &__description {
    max-width: 800px;
    min-width: 200px;
    margin-left: 8px;
    margin-right: 10px;
    h3 {
      font-weight: 300;
      color: $dark-75;
      margin-top: 15px;
    }
    @media (max-width: 1080px) {
      max-width: 600px;
    }
    @media (max-width: 768px) {
      max-width: 460px;
    }
  }
  &__list {
    margin: 0 5px 10px 0;
  }
  &__list-header {
    font-weight: 300;
    color: $dark-75;
    margin: 15px 0 5px;
    min-width: 150px;
  }
  &__ul {
    color: $primary;
    padding-left: 20px;
    li {
      h4 {
        color: $dark-60;
        margin: 10px 0;
        @media (max-width: 768px) {
          margin: 5px 0 10px;
        }
      }
    }
    @media (max-width: 768px) {
      margin-top: 8px;
    }
  }
  &__help {
    color: $blue;
    margin: 2px;
    cursor: help;
    &:hover {
      color: $blue--light;
    }
    .dd-menu-items {
      margin: 0 0 0 10px;
      .dd-items-left {
        min-width: 275px;
        padding: 5px 5px 5px 10px;
        li {
          margin: 5px 0;
          font-size: 15px;
          font-weight: 500;
          color: $dark-60;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
        }
      }
    }
  }
  &__dashed {
    list-style-type: none;
    padding-left: 8px;
  }
  &__requirements {
    text-indent: -5px;
  }
  &__exit {
    position: absolute;
    margin-top: 10px;
    right: 15px;
    color: $white;
    font-size: 1.5em;
    cursor: pointer;
  }
}
