@import 'color/colors';

.input-help {
  margin-top: -4px;
  display: contents;
  .dropdown {
    min-width: 260px;
    right: -12px;
    width: fit-content;
  }
}

.input-help__icon {
  width: 20px;
  text-align: center;
  border-radius: 0 2px 2px 0;
  font-size: 12px;
  color: $mint;
  transition: all 0.1s linear;
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  margin-top: -6px;
  cursor: pointer;
  svg {
    color: $mint;
  }
}
.input-help__icon:hover {
  color: $mint--light;
  font-weight: bold;
  svg {
    color: $mint--light;
  }
}

.input-help-item {
  @include text--body2;
  padding: 16px;
}
