@import 'color/colors';

.inline-input-icon {
  position: absolute;
  top: 1px;
  right: 1px;
  display: block;
  width: 42px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
  border-radius: 0 2px 2px 0;
}
