@import 'color/colors';

.assignTodo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
  margin: 0;
  padding: 20px;
  flex: 1;
  text-align: center;
  &__exit {
    position: absolute;
    align-self: flex-end;
    margin-top: -3px;
    font-size: 1.25em;
    color: $primary;
    cursor: pointer;
    &:hover {
      color: $primary--light;
    }
    &:active {
      color: $primary--dark;
    }
    &:focus {
      color: $primary--dark;
    }
  }

  form {
    flex: 1;
    display: inherit;
    flex-direction: column;
    .workbench-form__group {
      text-align: left;
    }
  }

  h2 {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: $mint;
    }
    svg {
      margin-left: 4px;
    }
  }

  h6 {
    margin: 5px 0 0;
    font-size: 0.75em;
  }
  p {
    flex: 1;
    margin-bottom: 0;
  }

  &__buttons {
    flex: 1;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin: 0 15px 15px;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    flex-direction: row;
    @media (max-width: 768px) {
      margin: 0 5px 5px;
    }
  }
}
