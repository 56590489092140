@import 'color/colors';

.select-resource {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 600px;
  .select-actions {
    height: 38px;
    width: 38px;
    font-size: 16px;
    color: $text--dark-1;
    border-radius: 19px;
    margin-left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.25s;
    &.selected {
      transition: all 0.25s;
      background-color: $bg--page;
      box-shadow: 3px 2px 3px 0px $light-110;
      color: $link;
      border: thin solid $link;
    }
    &:hover {
      background-color: $bg--page;
      color: $link;
      box-shadow: 3px 2px 3px 0px $light-110;
      border: thin solid $link;
    }
    &:active {
      background-color: $bg--page;
      color: $link;
      font-size: 26px;
      box-shadow: none;
      border: thin solid $link;
    }
  }
  .select-actions-popup {
    position: absolute;
    right: -40px;
    top: 20px;
    .dd-menu-items {
      margin-left: 20px;
      margin-top: 0px;
      width: 125px;
      font-size: 14px;
      font-weight: 300;
      ul {
        color: $dark-50;
        border-radius: 2px;
        li {
          padding: 8px;
          cursor: pointer;
          transition: all 0.25s;
          &:hover {
            background-color: $light-90;
          }
          &:active {
            background-color: $light;
          }
        }
      }
    }
  }
  &__option {
    display: flex;
    justify-content: space-between;
    .myBtn {
      background-color: transparent;
      position: absolute;
      right: 0;
      &__tooltip {
        right: 0;
      }
      &:hover {
        background-color: $white;
      }
    }
    &--focus {
      background-color: transparent;
    }
    &--delete {
      color: $orange;
      background-color: transparent;
      outline: none;
      border: none;
      cursor: pointer;
      width: 140px;
      &:hover {
        color: $orange--dark;
      }
    }
  }
}
