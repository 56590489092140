@import 'color/colors';

.verify-code__container {
  width: 540px;
  margin: 80px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 435px;
  .savvi-loading {
    margin: auto;
  }
  @media (max-width: 768px) {
    margin-top: 60px;
  }
}

.verify-code__logo {
  margin-bottom: 20px;
  img {
    width: 180px;
    transition: 0.05s all linear;
    cursor: pointer;
    filter: brightness(0);
  }
}

.verify-code__form {
  width: 100%;
  border-radius: 4px;
  border: thin solid $border;
  background-color: $white;
  border-left: none;
  border-right: none;
  padding: 18px 26px;
  margin: 0 auto;
  &-alert {
    background-color: $red--light-3;
    color: $red--dark;
    padding: 9px 20px;
    border-radius: 8px;
    max-width: 95%;
    margin: 12px 0;
  }
}

.verify-code__heading {
  @include text--h1;
  margin-top: 0;
  text-align: center;
}

.verify-code__sub-heading {
  display: block;
  margin-top: 12px;
  margin-bottom: 4px;
  color: $dark-85;
}

.verify-code__action {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .myBtn {
    width: 348px;
    margin-bottom: 2px;
    &--secondary {
      margin-top: 20px;
    }
    img {
      padding-right: 2px;
      margin-right: 6px;
      width: 20px;
    }
  }
  .myBtn--link {
    font-size: inherit;
    display: contents;
  }
  p {
    @include text--body1;
    // text-align: center;
    margin-top: 12px;
  }
}

.verify-code__email {
  cursor: pointer;
  margin-top: 8px;
}

.verify-code__text {
  text-align: center;
  p {
    margin-bottom: 12px;
  }
}
