@import 'color/colors';

.allDocsSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 100%;
  width: 100%;
  max-width: 1080px;
  padding: 0;
  margin: 40px 0 0;
  color: $dark-50;
  border-bottom: thin solid $light-95;

  &__header {
    // font-size: 1.3em;
    // color: $dark-80;
    // font-weight: 500;
    @include text--h1;
    svg {
      font-size: 1.25em;
      margin-top: 4px;
      margin-right: 5px;
      color: inherit;
    }
  }

  /** *** LIST START *** **/
  &__list {
    display: grid;
    grid-template-columns: minmax(240px, 3.5fr) minmax(120px, 210px) minmax(112px, 140px);
    width: 100%;
    // grid-template-columns: repeat(4, auto);
    justify-content: stretch;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 4px 10px 0 transparentize(#000000, 0.8);
    min-width: 740px;

    // @media (max-width: 1080px) {
    //   overflow-x: auto;
    // }

    &.unsetHeight {
      max-height: none;
    }
    &.myTasks {
      grid-template-columns: minmax(240px, 4fr) minmax(120px, 148px) minmax(120px, 160px) minmax(
          120px,
          140px
        );
      @media (max-width: 1080px) {
        .allDocsSection__listDocumentName {
          max-width: 400px;
        }
      }
    }

    &Header {
      display: flex;
      align-items: center;
      background-color: $white;
      position: sticky;
      top: 0;
      border-bottom: 2px solid $light-95;
      margin: 0;
      height: 40px;
      &Label {
        background-color: $white;
        height: 40px;
        cursor: pointer;
        margin: 0;
        padding-right: 8px;
        display: flex;
        align-items: center;
        // border-bottom: thin solid $light-95;
        top: 0;
        position: sticky;
        font-size: 14px;
        font-weight: 400;
        color: #afbdc5;

        &.first {
          padding-left: 40px;
        }
        svg {
          color: $link;
          margin-left: 2px;
        }
        &:hover {
          color: $dark-75;
          text-decoration: underline;
          svg {
            color: $link--dark;
          }
        }
      }
    }
    &Row {
      background-color: inherit;
      cursor: pointer;
      display: contents;
      &.showRecipients {
        .allDocsSection__list {
          &Item {
            padding-bottom: 18px;
          }
          &Actions {
            height: 40px;
          }
        }
      }
      &:hover {
        background-color: $hover;
        .allDocsSection__listActions {
          display: flex;
        }
      }
      .PDFViewer__wrapper {
        position: absolute;
        top: 8px;
        left: 4px;
        &.isPdfHiding {
          left: 8px;
          top: unset;
          margin-top: 0px;
          .PDFViewer__action {
            @include text--body1;
          }
        }
      }
      &--upload {
        .allDocsSection__listItem {
          padding-bottom: 44px;
        }
        .PDFViewer__wrapper {
          top: unset;
          margin-top: 40px;
        }
      }
      &--dataRoom:hover {
        cursor: default;
        background-color: inherit;
      }
    }
    &Item {
      align-items: center;
      background-color: inherit;
      border-top: 2px solid $light-95;
      display: flex;
      padding: 26px 8px 8px;
      justify-content: flex-end;
      margin: 0;
      overflow: visible;
      padding-right: 8px;
      position: relative;
      &.first {
        grid-column: 1;
        @include text--h2;
        padding-left: 40px;
        h4 {
          color: #0d2238;
          font-size: 16px;
          font-weight: bold;
          display: block;
          overflow: hidden;
        }
      }
      h4 {
        background-color: inherit;
        @include text--body1;
        justify-self: flex-start;
        width: 100%;
        position: relative;
      }
    }
    &Recipient {
      &s {
        grid-column-start: 1;
        grid-column-end: 5;
        background-color: inherit;
        padding-left: 40px;
        padding-bottom: 18px;
        margin: 0;
        p {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 12px;
          font-weight: 400;
        }
      }
      display: flex;
      @include text--body2;
      margin: 4px 0 8px;
      white-space: pre;
      &Label {
        width: 280px;
        display: flex;
        span {
          @include text--h4;
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        svg {
          margin-right: 2px;
        }
        @media (max-width: 876px) {
          width: 240px;
        }
      }
      svg {
        margin: 0 4px 0 2px;
      }
      &Status {
        @include text--body2;
        position: relative;
        cursor: pointer;
        transition: all 0.15s ease-in-out;
        p {
          @include text--body2;
          margin-right: 8px;
          display: flex;
          align-items: center;
          text-decoration: underline;
          width: 120px;
          display: flex;
          justify-content: space-between;
          span {
            flex: 1;
          }
          svg {
            font-size: 12px;
            transition: 0.3s;
            flex: 0.2;
          }
        }
        .dropdown {
          right: -4px;
        }
        &:hover {
          p {
            color: $link--light;
          }
        }
        &--hideActions {
          pointer-events: none;
        }
      }
      &Warning {
        color: $orange;
        font-size: 14px;
      }
      &Chevron {
        width: 22px;
      }
    }
    &Toggle {
      grid-column-start: 1;
      grid-column-end: 5;
      margin: 4px 0;
      color: $text--dark-1;
      svg {
        margin-left: 4px;
      }
      &:hover {
        color: $link--light;
      }
    }
    &Actions {
      align-items: center;
      backdrop-filter: blur(1px);
      background-color: transparent;
      display: none;
      height: 60px;
      justify-content: flex-end;
      margin-right: 20px;
      position: absolute;
      width: fit-content;
      &--open {
        background-color: transparent;
        display: flex;
        .myBtn--icon {
          border-color: $border--light;
          &:disabled {
            border-color: $light-110;
            cursor: not-allowed;
            pointer-events: unset;
          }
          &:hover {
            border-color: $link--light;
            color: $link--light;
            &:disabled {
              border-color: $light-110;
              color: $light-110;
            }
            &.isWarning {
              color: $warning--light;
              border-color: $warning--light;
            }
          }
        }
      }
      .myBtn {
        margin: 0 12px;
        opacity: 1;
        background-color: $white;
      }
    }
  }
  /** *** LIST END *** **/
}
