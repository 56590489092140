.solutions-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 200px;
  grid-gap: 12px 16px;
  margin-bottom: 36px;
}

.solution {
  box-sizing: border-box;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  background: white;
  transition: all 0.15s;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.solution__title {
  padding: 16px 0 0 18px;
  font-size: 16px;
  font-weight: bold;
  display: block;
  color: $primary--dark;
}

.solution__icon {
  font-size: 64px;
  color: white;
  text-align: center;
  justify-self: stretch;
}

.solution__icon--inner {
  color: $primary;
}

.solution__icon img {
  height: 71px;
}

.solution__cta {
  display: block;
  background-color: $primary;
  color: white;
  font-size: 14px;
  text-align: center;
  padding: 12px;
  font-weight: bold;
}
