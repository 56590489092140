@import 'color/colors';

.data-room--modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 60vw;
  padding: 20px;
  @media (max-width: 825px) {
    padding: 10px;
  }
}

.data-room--card {
  display: flex;
  flex-direction: column;

  h2 {
    color: $dark-50;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 5px;
    line-height: 32px;
    align-self: center;
  }

  p {
    color: $dark-50;
    line-height: 24px;
    margin: 0;
    margin-bottom: 5px;
  }
}

.preview-modal-fees__details {
  color: $dark-50;
  font-size: 12px;
  display: block;
  margin: 0;
}

.data-room__button {
  margin: 20px auto 10px;
}

.data-room__link {
  align-self: center;
  .myBtn {
    margin-right: 8px;
  }
}

.data-room__fetching {
  align-self: center;
  font-size: 24px;
}
