@import 'color/colors';

.input-verification {
  &__container {
    position: relative;
  }
  &__input {
    display: flex;
    align-items: center;
    input {
      border: solid 1px #a8adb7;
      border-right: none;
      font-family: 'Lato';
      font-size: 20px;
      color: #525461;
      text-align: center;
      box-sizing: border-box;
      border-radius: 0;
      -webkit-appearance: initial;
      &.last {
        border-right: solid 1px #a8adb7;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &:focus {
        outline: none;
        border: 1px solid #006fff;
        caret-color: #006fff;
      }
      &:focus + input {
        border-left: none;
      }
    }
    svg {
      margin-left: 8px;
      font-size: 20px;
      color: $text--dark-1;
      cursor: pointer;
      &:hover {
        color: $text--dark;
      }
    }
  }
  &__label {
    @include text--body1;
  }
  &__loading {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    &-blur {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      opacity: 0.5;
      filter: blur(0.5px);
      transition: opacity 0.3s;
    }
    svg {
      width: 40px;
      filter: brightness(0);
    }
  }
}
