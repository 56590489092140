.billing-columns {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.billing-column__title {
  font-size: 21px;
  line-height: 24px;
  font-weight: 700;
  padding: 0;
  margin: 0 0.75em 0 0;
  color: $type--medium;
}

.billing-column {
  flex: 1 1 auto;
  margin: 0 8px 16px;
  max-width: calc(33% - 16px);
  width: calc(33% - 16px);
  min-width: 350px;
}

.billing-card {
  padding: 24px;
  min-height: 264px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  background-color: white;
}

.method-card {
  min-height: 160px;
  height: 160px;
  padding: 24px;
  letter-spacing: 0.5px;
}
.billing-payment__details {
  display: flex;
  align-content: flex-start;
  margin-top: 24px;
}

.billing-payment__type {
  font-size: 82px;
  padding-right: 24px;
  color: $primary--dark-1;
  line-height: 1;
}

.billing-payment__info {
  display: block;
  font-size: 16px;
  padding-top: 4px;
}

.billing-payment__exp {
  display: block;
  color: $type--light;
  font-size: 12px;
  font-weight: lighter;
  margin-top: 4px;
}

.add-method__card {
  @include font--primary-regular;
  width: 100%;
  height: 160px;
  border: 2px dashed rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 21px;
  vertical-align: center;
  font-weight: 800;
  background-color: rgba(0, 0, 0, 0);
  color: $primary;
  cursor: pointer;
  font-weight: normal;
  &:hover {
    background-color: transparentize($primary, 0.95);
  }
}

.add-method__icon {
  display: inline-block;
  margin-right: 8px;
  font-size: 18px;
}

.method__default {
  position: absolute;
  bottom: 25px;
  font-size: 13px;
  color: $yellow--dark;
  font-weight: bold;
}

.edit-method__default {
  font-size: 13px;
  color: $yellow--dark;
  font-weight: bold;
}

.method__detail {
  display: block;
}

.method__warning {
  display: block;
  color: $red--light;
}

.method__info {
  display: block;
  font-size: 24px;
}

.method__exp {
  display: block;
  color: $type--light;
  font-size: 12px;
  font-weight: lighter;
  margin-top: 4px;
}

.method__type {
  font-size: 32px;
  color: $light;
  position: absolute;
  right: 0;
  top: 0;
  margin: 24px;
}

.method__type--edit {
  margin: 0;
}

.add-method__modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid #ccc;
  background: $white;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 24px 36px 48px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 640px;
  min-width: 540px;
  max-height: 720px;
}

.method__remove {
  background-color: $red--light-1;
  font-weight: bold;
  display: inline-block;
  transition: 0.05s all linear;
  color: white;
  min-width: 85px;
  cursor: pointer;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 0.5em 0.75em;
  &:hover {
    background-color: $red;
  }
}

.method-card__details {
  position: relative;
}

.edit-method__actions {
  display: flex;
  text-align: right;
  justify-content: space-between;
  margin-top: 32px;
}

.method__set-default {
  font-weight: bold;
  min-width: 85px;
  display: inline-block;
  transition: 0.05s all linear;
  cursor: pointer;
  text-align: center;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 0.5em 0.75em;
  color: $dark-85;
  border: thin solid $light-110;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.method__cardholder {
  margin-top: 16px;
}

.method__billing-zip {
  margin-top: 4px;
}

.btn-method-edit {
  position: absolute;
  bottom: 0;
  right: 0;
  background: transparent;
  border: none;
  border-radius: 3px;
  margin: 24px;
  color: $primary--light;
  &:hover {
    background-color: transparentize($primary, 0.85);
  }
}

.btn--cancel {
  order: 0;
}

.btn--add-method {
  order: 1;
}
