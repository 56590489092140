@import 'color/colors';

.addResource {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: 0;
  padding: 20px;
  flex: 1;
  text-align: center;
  &__exit {
    position: absolute;
    align-self: flex-end;
    margin-top: -3px;
    font-size: 1.25em;
    color: $primary;
    cursor: pointer;
    &:hover {
      color: $primary--light;
    }
    &:active {
      color: $primary--dark;
    }
    &:focus {
      color: $primary--dark;
    }
  }
  &__subheader {
    margin: 4px 0 12px;
    span {
      color: $red;
    }
    .myBtn {
      color: $text--dark-1;
      font-size: inherit;
      &:hover {
        color: $new-primary--light;
      }
    }
  }
  &__warning {
    color: $red--light;
    margin-right: 4px;
  }
  &__info {
    color: $mint;
    margin-right: 4px;
  }

  form {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
    align-items: center;
    .workbench-form__group {
      text-align: left;
    }
    .address-group__state {
      flex: 3.6;
    }
  }

  h2 {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: $red;
    }
    svg {
      margin-left: 4px;
    }
  }

  h6 {
    margin: 5px 0 0;
    font-size: 0.75em;
  }
  p {
    flex: 1;
    margin-bottom: 0;
  }

  &__links {
    width: fit-content;
    grid-column-start: 1;
    grid-column-end: 3;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__buttons {
    flex: 1;
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    -ms-flex-pack: distribute;
    justify-content: space-evenly;
    margin: 0 15px 15px;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    flex-direction: row;
    @media (max-width: 768px) {
      margin: 0 5px 5px;
    }
  }
}
