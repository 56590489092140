@import 'color/colors';

.dropdown {
  z-index: 100;
  position: absolute;
  top: 40px;
  right: 0;
  height: fit-content;
  width: 120px;
  border-radius: 4px;
  // border: thin solid $text--light;
  border: 1px solid #e8e8e8;
  background-color: $white;
  box-shadow: 0 4px 10px 0 transparentize(#000000, 0.8);
  // box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 10%);
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  border-bottom: none;
  &--left {
    right: unset;
    left: 0;
  }
  &--upward {
    top: unset;
    bottom: 40px;
  }

  // cursor: pointer;
  .myBtn {
    flex: 1;
    min-width: unset;
    width: 100%;
    border: none;
    border-bottom: thin solid $text--light-light;
    padding: 0;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    transition: 0.2s;
    background-color: $white;
    &:hover {
      text-decoration: underline;
    }
  }
}

.dropdown__wrapper {
  position: relative;
  // display: contents;
  .dropdown {
    width: 160px;
  }
}
