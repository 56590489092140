@import 'color/colors';

.orgButton {
  .dropdown {
    padding-top: 20px;
    width: 200px;
    right: 8px;
    border-bottom: thin solid $text--light;
    :last-child {
      border-bottom: none;
    }
    .myBtn--link {
      text-decoration: underline;
      text-align: start;
      color: $text--dark;
      padding: 0 20px;
      font-size: 10px;
      font-weight: 600;
      border: none;
      text-transform: uppercase;
      justify-content: flex-start;
      &:hover,
      &:active,
      &:focus {
        color: $link--light;
      }
    }
    &:before,
    &:after {
      border: none;
    }
  }
  h4 {
    @include text--h4;
    padding: 0 20px;
    max-height: 200px;
    overflow-wrap: break-word;
    overflow-y: auto;
  }
  h3 {
    @include text--h3;
    margin: 0 20px;
  }
  h2 {
    @include text--h2;
    font-weight: 400;
    margin: 0 20px;
  }
  p {
    @include text--body1;
  }

  &__toggle {
    color: $text--dark;
    max-width: 250px;
    text-align: start;
    display: flex;
    text-decoration: none;
    align-items: center;
    @media (max-width: 678px) {
      svg {
        font-size: 1.1em;
        color: $text--dark;
        &:hover {
          color: $new-primary;
        }
      }
    }
    &:hover,
    &:active,
    &:focus {
      color: $link--light;
      text-decoration: underline;
    }
    h4 {
      color: $text--dark;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 0.85px;
      padding-right: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-transform: uppercase;
    }
  }

  &__section {
    p {
      padding: 0;
      margin: 0;
    }
    h4 {
      margin: 4px 0;
    }
    a {
      padding-left: 0;
    }
  }

  &__separator {
    background-color: $border;
    height: 2px;
    width: 100%;
    margin: 8px 0 0;
  }
  &__name {
    @include text--h2;
  }
}
