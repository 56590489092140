/// Slightly mix lighten a color
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

$text--light: #afbdc5;
$text--light-1: #85949b;
$text--light-light: tint(#afbdc5, 20%);
$text--light-dark: shade(#afbdc5, 20%);

$text--sub: #8a8f92;
$text--dark: #0d2238;
$text--dark-light: tint(#0d2238, 20%);
$text--dark-dark: shade(#0d2238, 20%);
$text--dark-1: #60696f;
$text--dark-2: #2d363c;
$text--placeholder: #b7b6b6;
$text--dark-disabled: #8997a0ad;

$text--form: #afbdc5;

$text--button: #2d363c;

$border--dark: transparentize(#858d92, 0.5);
$border--light: #eae9e9;
$border--light-1: transparentize(#f8f8f8, 0.8);
$border--button: #2d363c;
$border--disabled: #e6e6e6;

$bg--nav: #2d363c;
$bg--hover: #afbdc5;
$bg--page: #f7f7f7;
$bg--table: #ffffff;
$new-primary: #0da1ff;
$new-primary--light: tint(#0da1ff, 20%);
$new-primary--dark: shade(#0da1ff, 20%);
$select--hover: #deebff;
$select--focus: #2684ff;

$text__label: #777777;
$text__label--disabled: #8e8e8ed0;

@mixin text--h1 {
  color: $text--dark;
  font-weight: bold;
  font-size: 22px;
  margin: 16px 0;
  span {
    font-weight: 400;
  }
}
@mixin text--h2 {
  color: $text--dark;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}
@mixin text--h3 {
  color: $text--dark;
  font-weight: bold;
  font-size: 12px;
  margin: 0;
}
@mixin text--h4 {
  color: $text--dark-1;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
}
@mixin text--label {
  color: $text__label;
  font-weight: 400;
  font-size: 14px;
  margin: 4px 0 4px 8px;
}
@mixin text--input-label {
  color: $text--light;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}
@mixin text--input {
  color: $text--dark-2;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}
@mixin text--sub {
  color: $text--sub;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
}
@mixin text--body1 {
  color: $text--dark;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}
@mixin text--body2 {
  color: $text--dark;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}
@mixin text--body-light {
  color: $text--dark-1;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}

$new-link: #3ba8ed;
$new-link--light: tint(#3ba8ed, 20%);
$new-link--dark: shade(#3ba8ed, 20%);

$low: #86dbc1;
$low--light: tint(#86dbc1, 20%);
$low--dark: shade(#86dbc1, 20%);
$med: #c2b946;
$med--light: tint(#c2b946, 20%);
$med--dark: shade(#c2b946, 20%);
$warning: #db6949;
$warning--light: tint(#db6949, 20%);
$warning--dark: shade(#db6949, 20%);
$bg--status: #60696f;
$bg--status-light: #a7aaac;

// Layout
$dark: #02080d;
$dark-85: #282d31;
$dark-80: #393a3d;
$dark-75: #4e5256;
$dark-60: #676b6e;
$dark-50: #818486;
$light-110: #bdc8cd;
$light: #dae0e3;
$light-95: #e9ecee;
$light-90: #f7f8f9;
$shadow: 0 4px 10px 0 transparentize(#000000, 0.8);
$shadow--dark: 0 4px 10px 0 transparentize(#000000, 0.2);

$white: #fff;

$border: #dbdee0;
$border--active: #2684ff;

$tooltip: rgba(70, 70, 70, 0.8);
$hover: #eaf4f9;
$transparentHover: rgba(234, 244, 249, 0.8);

// Core
// $orange: #E55920;  // new
$orange: #db6949; // old
$orange--dark: shade($orange, 20%);
$orange--light: tint($orange, 20%);

$yellow: $med;
$yellow--light: #e6cd73;
$yellow--light-1: #ecd993;
$yellow--light-2: #f1e3b1;
$yellow--light-3: #f6edcd;
$yellow--dark: #b2993e;
$yellow--dark-1: #6a5c25;

$red: #e04f4f;
$red--light: #e98282;
$red--light-1: #efa4a4;
$red--warning: #8800331a;
$red--light-2: #f3c0c0;
$red--light-3: #f8d7d7;
$red--dark: #b13e3e;
$red--dark-1: #682424;

$dark-cerulean: #0c6987;
$dark-cerulean--light: #13a3d4; // iris blue
$dark-cerulean--light-1: #0d7294;
$dark-cerulean--dark: #063747;
$dark-cerulean--dark-1: #0a556e;

$cerulean: #0771b3;
$cerulean--light: #0aa1ff; // dodger blue
$cerulean--light-1: #0879bf; // iris blue
$cerulean--dark: #054973;
$cerulean--dark-1: #066199; // dodger blue

$tiber: #0c5f64;
$tiber--light: #277175;
$tiber--light-1: #54818a;
$tiber--light-2: #38666f;
$tiber--light-3: #235058;
$tiber--light-4: rgba(152, 191, 199, 0.781);
$tiber--dark: #083138;
$tiber--dark-1: #032228;
// $tiber           : #143D45;
// $tiber--light    : #65939C;
// $tiber--light-1  : #54818A;
// $tiber--light-2  : #38666F;
// $tiber--light-3  : #235058;
// $tiber--dark     : #083138;
// $tiber--dark-1   : #032228;

$powder-blue: #bde3e5;
$powder-blue--light: #d4f6f8;
$powder-blue--background: #f6f7ff;

// $mint: #29d3c5;
$mint: #86dbc1;
$mint--light: tint(#86dbc1, 10%);
// $mint--light: #81f3ea;
$mint--light-background: #cff5f3ea;
$mint--light-background-1: #cff5f394;
$mint--dark: #00b9aa;
$notice--text: #59a59f;

// $link: #0772b3;
// $link--light: #0e96eb;
// $link--dark: #0b5d91;
$link: $new-primary;
$link--light: $new-primary--light;
$link--dark: $new-primary--dark;

$blue: #21a0ff;
$blue--light: #35a8ff;
$blue--light-1: #5abaff;
$blue--light-2: #85ccff;
$blue--light-3: #aadbff;
$blue--dark: #1c83d1;
$blue--dark-1: #061e2f;

$green: #128c85;
$green--light: #52aba5;
$green--light-1: #81c2bd;
$green--light-2: #a7d4d1;
$green--light-3: #c8e4e2;
$green--dark: #0e6e68;
$green--dark-1: #08403c;

// $workbench--background: #f3f9ff;
$workbench--background: $bg--page;

$modal--background: #f3f9ffc2;

$primary: $dark-cerulean;
$primary--light: $dark-cerulean--light;
$primary--light-1: tint($dark-cerulean--light, 20%);
$primary--dark: $dark-cerulean--dark;
$primary--dark-1: $dark-cerulean--dark-1;
