@import 'color/colors';
@import 'navigation/navigation';

.footer__main {
  background-color: $white;
  flex-shrink: 1;
  width: 100%;
  clear: both;
  border-top: 1px solid $light-95;
  z-index: $sidebar-z-index - 1;
  position: fixed;
  bottom: 0;
  left: 0;
}

.footer__list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  margin: 0;
  padding: 0 0 0 5px;
  list-style: none;
  margin-right: 95px;
}

.footer__logo {
  height: 16px;
  color: $dark;
  filter: brightness(0);
}

.footer__list-item {
  margin: 0 4px;
  display: flex;
  font-size: 10px;
  color: $dark;
}

.footer__link {
  padding: 5px;
}

.footer__link:hover {
  text-decoration-line: underline;
}
