@import 'color/colors';

.finalizeReviewModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: 0;
  padding: 20px;
  flex: 1;
  text-align: center;
  p {
    @include text--body1;
    margin: 4px 0;
    text-align: left;
    svg {
      color: $med;
      margin-right: 4px;
    }
  }
  h2 {
    @include text--h1;
    margin-top: 0;
    span {
      color: $mint;
    }
  }
  h6 {
    margin: 5px 0 0;
    font-size: 0.75em;
  }
  &__info {
    display: flex;
    flex-direction: column;
    max-height: 450px;
    overflow-y: auto;
    border-bottom: thin solid $border;
    &.visible {
      overflow-y: visible;
    }
    h4 {
      @include text--body1;
      text-align: left;
      span {
        font-weight: bolder;
        color: $dark;
        h4 {
          @include text--h4;
        }
        li {
          @include text--body2;
        }
      }
      svg {
        color: $mint--light;
        margin-right: 2px;
      }
    }
  }
  &__header {
    text-align: start;
    margin: 0 8px 8px 0;
    h4 {
      @include text--h3;
    }
    p {
      @include text--body1;
      margin: 5px 5px 5px;
      color: $dark-50;
    }
  }
  &__row {
    h4 {
      @include text--h4;
      margin: 5px;
      margin-left: 0;
      p {
        display: inline;
        @include text--body2;
      }
    }
    p {
      @include text--body2;
      a {
        color: $text--dark-1;
        text-decoration: underline;
        margin-left: 4px;
        &:hover {
          color: $new-primary--light;
        }
      }
    }
  }
  &__help {
    h4 {
      margin-top: 8px;
      margin-bottom: 8px;
      color: $dark-50;
    }
    h6 {
      margin: 0;
      margin-top: -5px;
      color: $dark-60;
    }
    sup {
      svg {
        color: $link;
        margin-left: 2px;
        &:hover {
          color: $link--light;
          cursor: pointer;
        }
      }
    }
    .dd-menu-items {
      margin: 0 0 0 10px;
      z-index: 100;
      .dd-items-center {
        min-width: 240px;
        text-align: left;
        padding: 5px 5px 5px 10px;
        li {
          margin: 5px 0;
          font-size: 15px;
          font-weight: 500;
          color: $dark-60;
          display: flex;
        }
        ul {
          box-shadow: none;
          border: none;
        }
        p a {
          margin: 0;
          padding: 0;
          min-width: 0;
          color: $link;
          &:hover {
            color: $link--dark;
            text-decoration: underline;
          }
        }
      }
    }
  }
  &__exit {
    position: absolute;
    align-self: flex-end;
    margin-top: -3px;
    font-size: 1.25em;
    color: $primary;
    cursor: pointer;
    &:hover {
      color: $primary--light;
    }
    &:active {
      color: $primary--dark;
    }
    &:focus {
      color: $primary--dark;
    }
  }
  &__redlining {
    // color: $dark-80;
    text-align: left;
    margin: 4px 0;
    h4 {
      @include text--h3;
      margin: 0 0 4px;
      color: $dark-80;
    }
    .styled-checkbox {
      margin-bottom: 4px;
      .styled-checkbox__label {
        padding: 2px 0 2px 12px;
      }
      .styled-checkbox__label {
        color: $dark-75;
      }
    }
    h6 {
      margin: 0;
    }
  }

  &__upgrade {
    margin: 0 0 4px;
    text-align: left;
    svg {
      color: $mint;
      margin-right: 4px;
    }
    a {
      color: $link;
      text-decoration: underline;
      &:hover {
        color: $link--light;
        text-decoration: underline;
      }
    }
  }

  &__buttons {
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin: 10px 15px 15px;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    flex-direction: row;
    @media (max-width: 768px) {
      margin: 5px;
    }
  }
}
