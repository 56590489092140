.settings__form {
  width: 90%;
  max-width: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .myBtn {
    align-self: center;
  }
}

.form--add-method {
  max-width: 450px;
  margin: 0;
}
