@import 'color/colors';

.resourceList {
  display: grid;
  grid-template-columns: repeat(5, auto);
  padding: 16px;
  margin-bottom: 4px;
  border: thin solid $border;
  border-radius: 10px;
  max-width: 600px;
  overflow-x: auto;
  &__container {
    width: 100%;
    max-width: 600px;
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    &--error {
      .address-group__label {
        color: $red;
      }
    }
  }
  &__question {
    @include text--label;
    position: relative;
  }
  &__empty {
    @include text--h2;
    color: $text--light;
    margin: 8px 0;
  }
  &__subquestion {
    @include text--body2;
    .myBtn {
      font-size: inherit;
    }
  }
  &__info {
    color: $mint;
    margin-right: 4px;
  }
  &__header {
    @include text--input-label;
    font-size: 12px;
    width: 100%;
    padding: 4px 12px 4px 0;
    margin: 0;
    border-bottom: thin solid $light-95;
    display: flex;
    align-items: flex-end;
    &.first {
      grid-column: 1;
    }
  }
  &__item {
    padding: 10px 4px;
    margin: 0;
    @include text--body1;
    border-top: thin solid $border;
    // max-width: 112px;
    word-break: break-word;
    &.first {
      grid-column: 1;
      cursor: pointer;
      min-width: 140px;
      svg {
        color: $text--dark-1;
        margin-right: 4px;
      }
      &:hover {
        color: $link--light;
        text-decoration: underline;
        svg {
          color: $link--light;
        }
      }
    }
    &--locked {
      pointer-events: none;
    }
    .myBtn--link {
      padding-left: 0;
      svg {
        margin-right: 4px;
      }
    }
  }
  &__preview {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__add {
    justify-content: flex-start;
    grid-column-start: 1;
    grid-column-end: 3;
    margin-top: 12px;
    color: $text--dark-1;
    svg {
      margin-right: 4px;
      color: $link;
    }
    &:hover {
      color: $link--light;
    }
  }
}
