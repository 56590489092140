@import 'navigation/navigation';
@import 'color/colors';

.workbench__container {
  width: 100%;
  display: flex;
  flex: 1;
  background-color: $workbench--background;
  &.info-sidebar-collapsed {
    .workbench__main {
      margin-right: 60px;
    }
    .infoSidebar__container {
      width: 60px;
      overflow: hidden;
    }
    .workbench__toggle-info-sidebar {
      right: 0;
    }
  }
}

.workbench__main {
  margin-top: $topbar-height;
  margin-right: $info-sidebar-width;
  display: flex;
  // flex-direction: column;
  background-color: $workbench--background;
  flex: 1;
  overflow-x: hidden;
  @media (min-width: 1540px) {
    margin-right: 0;
  }
  @media (max-width: 1280px) {
    margin-right: $info-sidebar-width--sm;
  }
  @media (max-width: 876px) {
    margin-right: $info-sidebar-width--mobile;
  }
}

.workbench__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  align-items: flex-start;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 40px 80px 160px;
  max-width: 960px;
  & > div,
  & > h2 {
    min-width: 680px;
  }
  @media (min-width: 1780px) {
    padding: 60px 100px 160px;
    max-width: 1000px;
  }
  @media (max-width: 1440px) {
    padding: 40px 40px 100px;
  }

  @media (max-width: 1080px) {
    padding: 40px 20px 100px;
  }
  @media (max-width: 876px) {
    padding: 34px 12px 100px;
  }
}

.workbench__banner {
  width: 100%;
  height: 30px;
  z-index: 4;
  background-color: $warning--light;
  margin-top: 2px;
  position: fixed;
  top: 58px;
  left: 0;
  right: 0;
  padding-left: 200px;
  @media (max-width: 1280px) {
    padding-left: 70px;
    overflow-x: auto;
  }
  &.second {
    top: 30px;
  }
  h4 {
    @include text--h2;
    color: $white;
    text-align: center;
    margin: 0;
    line-height: 30px;
    white-space: nowrap;
    svg {
      color: $red;
      margin-right: 4px;
    }
    a {
      color: $link;
      text-decoration: underline;
      &:hover {
        color: $link--light;
      }
    }
    @media (max-width: 1280px) {
      @include text--h3;
      color: $white;
    }
    @media (max-width: 876px) {
      @include text--h4;
      color: $white;
    }
  }
}

.workbench__loader {
  align-self: center;
  // position: absolute;
  // bottom: 50%;
  text-align: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 1em;
  font-size: 42px;
  color: $primary;
  img {
    width: 2em;
  }
}

.workbench__loading {
  display: block;
  align-self: center;
  padding: 0.25em;
  color: $text--dark;
}

.workbench__label {
  @include text--h3;
  color: $text--dark-1;
  display: flex;
  font-size: 14px;
  color: $text--dark;
}

.workbench__toggle-info-sidebar {
  position: absolute;
  top: 60px;
  right: 180px;
  height: 40px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: $text--dark-1;
    font-size: 20px;
  }
  &:hover {
    cursor: pointer;
    svg {
      color: $link;
    }
  }
}
