@import 'color/colors';

.register {
  width: 524px;
  margin: 40px auto 0;
  border: thin solid $border;
  background-color: $white;
  border-radius: 4px;
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: 20px;
    width: 95vw;
  }
}

.register__logo {
  img {
    width: 180px;
    transition: 0.05s all linear;
    cursor: pointer;
    filter: brightness(0);
  }
}

.register__form {
  max-width: 540px;
  margin: 10px 32px;

  .styled-checkbox__row label {
    @include text--body1;
  }
  @media (max-width: 768px) {
    margin: 10px auto;
  }
  a:hover {
    text-decoration: underline;
    color: $link--light;
  }
}

.register__actions {
  display: flex;
  justify-content: space-between;
  .oldbtn {
    margin: 10px 0;
  }
}

.register__header {
  text-align: center;
  font-weight: 400;
  color: #434c5f;
  margin-bottom: 40px;
}
