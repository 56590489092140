@import 'color/colors';
@import 'navigation/navigation';

.room__container {
  width: 100%;
  display: flex;
  flex: 1 1;
  background-color: $workbench--background;
  .infoSidebar__container {
    bottom: 12px;
  }
  .dashboard-page__loading--large {
    top: 70px;
  }
}

.room__error {
  @include text--h2;
  color: $text--dark-1;
  margin: 0 0 8px;
  .myBtn--link {
    font-size: inherit;
  }
}

.room__main {
  display: flex;
  flex: 1;
  margin-top: $topbar-height;
  overflow-x: hidden;
  &--show-faq {
    @media (max-width: 1440px) {
      margin-right: $info-sidebar-width;
      .room__content {
        padding: 40px 40px 100px;
        max-width: 880px;
        &.is-outside {
          max-width: unset;
        }
      }
    }
    @media (max-width: 1280px) {
      margin-right: $info-sidebar-width--sm;
    }
    @media (max-width: 876px) {
      margin-right: $info-sidebar-width--mobile;
    }
  }
  @media (min-width: 1540px) {
    margin-right: 0;
  }
}

.room__content {
  background-color: $workbench--background;
  flex: 1;
  overflow-x: auto;
  margin-bottom: 20px;
  flex-direction: column;
  padding: 40px 80px 160px;
  max-width: 960px;
  @media (min-width: 1780px) {
    padding: 60px 100px 160px;
    max-width: 1000px;
  }
  @media (max-width: 1440px) {
    .outside-form__main {
      max-width: 100%;
      padding-right: 300px;
    }
  }

  @media (max-width: 1080px) {
    padding: 40px 20px 100px;
  }
  @media (max-width: 876px) {
    padding: 34px 12px 100px;
  }
  h1 {
    @include text--h1;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1540px) {
      justify-content: flex-start;
      .myBtn--link {
        margin-left: 12px;
      }
    }
  }
  h3 {
    @include text--h3;
    margin: 4px 0;
  }
  h4 {
    @include text--h4;
    margin: 0 0 4px;
    display: flex;
    align-items: center;
    a {
      display: contents;
    }
  }
  p {
    @include text--body2;
    margin: 4px 0;
  }
  &.is-outside {
    padding: 0;
  }
  .basic-topbar {
    display: none;
  }
  .outside-form__main {
    margin: 0;
    padding: 0;
  }
  // .outside-form__content {
  //   padding: 0;
  // }
}

.room__action {
  margin-top: 8px;
  color: $text--dark-1;
  svg {
    color: $link;
    margin-right: 4px;
    margin-top: 2px;
  }
  &:hover {
    color: $link--light;
    svg {
      color: $link--light;
    }
  }
}

.room__subheader {
  @include text--h2;
  color: $text--dark-1;
  margin: 8px 0;
  display: flex;
  align-items: center;
  .dropdown {
    right: -128px;
    width: 200px;
  }
}

.room__user {
  margin-top: 20px;
  &Row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  h3 {
    margin-bottom: 8px;
  }
  h4 {
    @include text--h4;
  }
}

.room__section {
  margin: 24px 0;
  max-width: 1000px;
  min-width: 680px;
  h2 {
    @include text--h2;
    margin: 0 0 12px;
  }
  .allDocsSection {
    margin-top: 0;
    &__list {
      min-width: 100%;
    }
  }
}

.room__resources {
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0 4px 10px 0 transparentize(#000000, 0.8);
  background-color: $white;
  padding: 16px 36px 16px;
  position: relative;
  div:last-child {
    border-bottom: none;
  }
}

.room__resource {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: thin solid $border;
  background-color: $white;
  display: flex;
  &Type {
    @include text--h2;
    color: $text--dark-1;
    margin: 0 0 8px;
    padding-bottom: 8px;
    border-bottom: thin solid $border;
    display: flex;
    align-items: center;
    .myBtn--icon {
      color: $text--dark-1;
      margin-left: 4px;
      &:hover {
        color: $link--light;
      }
    }
  }
  &Name {
    @include text--h2;
    margin: 0 0 12px;
    margin-right: 20px;
    flex: 1.5;
    line-height: 34px;
    display: flex;
  }
}

.room__addResource {
  margin-top: 8px;
}
.room__modal {
  &-overlay {
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    overflow-y: auto;
    -webkit-overflow-scrolling-: 'touch';
    .myBtn--link {
      align-self: flex-start;
    }
  }
  h3 {
    @include text--h3;
    margin-bottom: 8px;
  }
  h4 {
    @include text--h4;
  }
  p {
    @include text--body1;
    margin-bottom: 4px;
  }
  position: relative;
  max-width: 90%;
  min-height: 260px;
  min-width: 540px;
  padding: 20px 40px 20px;
  outline: none;
  margin: auto;
  border: none;
  box-shadow: 3px 3px 3px 3px #33373859;
  display: flex;
  flex-direction: column;
  background-color: $white;
  &--sm {
    min-height: 220px;
  }
  &--lg {
    min-height: 340px;
  }
  &Actions {
    flex: 3;
    display: flex;
    align-items: flex-end;
    margin-top: 12px;
    justify-content: space-around;
  }
  &-exit {
    position: absolute;
    align-self: flex-end;
    margin-top: -3px;
    font-size: 1.25em;
    color: $primary;
    cursor: pointer;
    &:hover {
      color: $primary--light;
    }
    &:active {
      color: $primary--dark;
    }
    &:focus {
      color: $primary--dark;
    }
  }
  &-preview {
    height: 400px;
    margin-bottom: 20px;
  }
  &-option {
    &-wrapper {
      display: flex;
      align-items: flex-start;
      &:hover {
        background-color: $select--hover;
      }
      &--selected {
        background-color: $select--focus;
        .room__modal-option {
          color: $white;
        }
        &:hover {
          background-color: $select--focus;
        }
      }
    }
    @include text--h4;
    margin-top: 10px;
    display: flex;
    color: $low;
    background-color: transparent;
    text-align: right;
    outline: none;
    border: none;
    cursor: pointer;
    min-width: fit-content;
    margin-right: 12px;
    &--low {
      color: $mint;
    }
  }
  h2 {
    margin: 0;
    display: flex;
    align-items: center;
    span {
      color: $red;
    }
    svg {
      margin-left: 4px;
    }
  }
  .myBtn--link {
    padding-left: 0;
    margin-bottom: 8px;
    margin-top: -8px;
  }
  .bcu-footer {
    position: absolute;
    height: 0;
    width: 0;
    margin: 0;
    overflow: hidden;
    .bcu-footer-left {
      width: 0;
      overflow: hidden;
    }
  }
}

.room__features {
  flex: 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  div {
    margin-bottom: 8px;
    p a {
      color: $text--dark-1;
      text-decoration: underline;
      &:hover {
        color: $new-primary--light;
      }
    }
  }
}

.room__box-explorer {
  margin-top: 20px;
  padding: 8px 12px 12px;
  border-radius: 4px;
  box-shadow: 0 4px 10px 0 transparentize(#000000, 0.8);
}

.room__loader {
  align-self: center;
  text-align: center;
  font-size: 24px;
  color: $primary;
  margin: auto;
  padding-bottom: 110px;
  img {
    width: 2em;
  }
}

.room__grouped-logs {
  margin-top: 20px;
  h4 {
    @include text--h4;
    margin: 4px 0;
  }
  p {
    @include text--body2;
  }
  .myBtn--link {
    margin-left: 0;
    padding-left: 0;
    margin: 0 0 4px;
  }
}

.room__tags {
  display: flex;
  margin: 8px 0 12px;
  align-items: center;
  p {
    padding: 2px 8px;
    margin: 0 4px 0 0;
    background-color: $bg--status;
    border-radius: 18px;
    color: $white;
    &.low {
      background-color: $low;
    }
    &.med {
      background-color: $med;
    }
  }
}

.room__access-logs {
  margin-bottom: 8px;
}

.room__section-header {
  margin-bottom: 5px;
}

.room__btn {
  margin-top: 15px;
  // align-self: flex-end;
  box-shadow: -2px 4px 4px 0 #ccc;
}

.room__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.room__bottom {
  bottom: 26px;
  left: 0;
  right: 0;
  position: fixed;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: $white;
  border-top: 1px solid $light-95;
  z-index: $info-bar-z-index - 1;
}
