@import 'color/colors';

.template-link {
  flex: 1;
  position: relative;
  width: 100%;
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  &__label {
    @include text--label;
    position: relative;
  }
}
