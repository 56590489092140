@import 'color/colors';

.landing-topbar {
  display: flex;
  padding: 15px 25px;
  align-items: center;
  width: 100%;
  color: $dark-85;
  justify-content: space-between;
  height: 50px;
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  top: 0;
  border-bottom: thin solid $light;
  @media (max-width: 768px) {
    padding: 5px;
  }
  @media (max-width: 576px) {
    max-width: 100vw;
  }
}

.landing-topbar__wordmark {
  height: 35px;
  padding-left: 35px;
  filter: brightness(0);
  transition: 0.1s all linear;
  &:hover {
    filter: brightness(0) invert(67%) sepia(66%) saturate(5284%) hue-rotate(161deg)
      brightness(95%) contrast(85%);
  }
  @media (max-width: 768px) {
    padding-left: 0;
    height: 2em;
  }
  @media (max-width: 410px) {
    padding-left: 0;
    height: 1.75em;
  }
}

.landing-topbar-menu__link {
  padding-left: 10px;
  display: flex;
  color: $dark-75;
  transition: 0.1s all linear;
  padding-left: 40px;
  flex: 1;
  &:hover {
    color: $mint;
  }
  &:active {
    color: $mint--dark;
  }
  @media (max-width: 768px) {
    padding-left: 5px;
    font-size: 1em;
  }
  @media (max-width: 410px) {
    font-size: 0.95em;
  }
}

.landing-topbar__actions {
  display: flex;
  align-items: center;
  margin-right: 5px;
  &-phone {
    color: $mint;
    &:hover {
      color: $mint--light;
    }
  }
  .myBtn--primary {
    margin-left: 10px;
  }
  margin-left: 5px;
  @media (max-width: 768px) {
    button {
      margin-left: 5px;
    }
    &-phone {
      font-size: 0.85em;
    }
  }
  @media (max-width: 576px) {
    // flex-direction: column;
    &-phone {
      display: none;
      // position: absolute;
      // top: 55px;
      // right: 5px;
    }
  }
  @media (max-width: 410px) {
    button {
      margin-left: 5px;
    }
    &-phone {
    }
  }
}

.landing-topbar__button {
  border-radius: 0;
  width: 100px;
  height: 45px;
  text-align: center;
  vertical-align: middle;
  font-size: 1em;
  cursor: pointer;
  border-radius: 2px;
  @media (max-width: 768px) {
    width: 6.2em;
    height: 2.85em;
    font-size: 0.85em;
  }
  @media (max-width: 576px) {
    font-size: 0.85em;
  }
  @media (max-width: 410px) {
    font-size: 0.75em;
    width: 5.5em;
  }
}

.landing-topbar__button--primary {
  border: thin solid $mint;
  background-color: $mint;
  color: $white;
  a {
    margin: auto;
    color: $white;
    padding: 0;
    text-align: center;
  }
  &:hover {
    background-color: $mint--light;
  }
  &:active {
    background-color: $mint--dark;
    outline: none;
  }
  &:focus {
    background-color: $mint--dark;
  }
}

.landing-topbar__button--secondary {
  color: $mint;
  border: thin solid $mint;
  background-color: $white;
  a {
    margin: auto;
    color: $mint;
    padding: 0;
    text-align: center;
  }
  &:hover {
    background-color: $mint--light-background-1;
  }

  &:active {
    outline: none;
    background-color: $mint--light;
    color: $white;
  }

  &:focus {
    background-color: $mint--light;
    color: $white;
  }

  &:disabled {
    background-color: $light;
    border: thin solid $light;
    color: $mint--light;
  }
}

// .landing-topbar__wordmark {
//   animation: App-logo-spin infinite 20s linear;
//   height: 80px;
// }

// @keyframes App-logo-spin {
//   from { transform: rotate(0deg); }
//   to { transform: rotate(360deg); }
// }
