@import 'color/colors';

.input-select {
  &__container {
    display: flex;
    flex-direction: column;
    margin: 16px 0;
    max-width: 600px;
    width: 100%;
  }
  &__wrapper {
    background-color: $white;
    border-radius: 10px;
    display: inline-block;
    height: 52px;
    max-width: 600px;
    position: relative;
    width: 100%;
    &--error {
      border-color: $red;
      .input-select__label {
        color: $red;
      }
    }
    &--disabled {
      .input-select__label {
        color: $text__label--disabled;
        top: 4px;
        background-color: transparent;
      }
    }
  }
  &__label {
    @include text--input-label;
    color: $text__label;
    background: $white;
    font-size: 12px;
    left: 14px;
    padding: 0 2px;
    max-width: calc(100% - 24px);
    margin: 0;
    position: absolute;
    top: -8px;
    z-index: 2;
    display: flex;
  }
}
