@import 'color/colors';

.input-password {
  &__container {
    display: flex;
    flex-direction: column;
    margin: 16px 0;
    max-width: 600px;
    width: 100%;
  }
  &__wrapper {
    background-color: $white;
    border-radius: 10px;
    border: thin solid #afbdc5;
    display: inline-block;
    height: 52px;
    max-width: 600px;
    position: relative;
    width: 100%;
    &--error {
      border-color: $red;
      .styled-text-field__label {
        color: $red;
      }
    }
    &--disabled {
      background-color: #eee;
      border-color: $border--disabled;
      .input-password__label {
        color: $text__label--disabled;
      }
    }
    &--active {
      .input-password__label {
        color: $text__label;
        font-size: 12px;
        font-weight: 500;
        cursor: default;
        font-style: normal;
        left: 20px;
        top: -8px;
        height: fit-content;
        background: $white;
      }
    }
    &--hidden {
      position: absolute;
      height: 0;
      width: 0;
      margin-bottom: 0;
      overflow: hidden;
    }
  }
  &__label {
    @include text--input-label;
    background: transparent;
    font-style: italic;
    cursor: text;
    height: 50px;
    align-items: center;
    display: flex;
    left: 44px;
    position: absolute;
    top: 0;
    transition: left 0.2s ease-in-out, top 0.2s ease-in-out, font-size 0.2s ease-in-out;
    z-index: 3;
  }
  @include text--input;
  border-radius: 10px;
  border: none;
  height: 100%;
  display: flex;
  align-items: center;
  outline: none;
  padding: 0 20px 0 16px;
  position: relative;
  width: 100%;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: visible;

  &--has-icon-left {
    padding-left: 44px;
    padding-right: 16px;
  }
  &__control {
    text-security: disc;
    -webkit-text-security: disc;
    -mox-text-security: disc;
  }
  &__toggle {
    background-color: transparent;
    cursor: pointer;
    height: 50px;
    left: 8px;
    line-height: 50px;
    margin-top: 0;
    position: absolute;
    text-align: center;
    top: 0;
    transition: all 0.2s ease-in-out, top 0.2s ease-in-out, font-size 0.2s ease-in-out;
    width: 40px;
    svg {
      color: $text--button;
    }
    &:hover svg {
      color: $link--light;
    }
  }
}
