@import 'color/colors';

.dataTable {
  display: grid;
  grid-template-columns: repeat(5, auto);
  max-width: 1600px;
  margin-bottom: 8px;
  overflow-x: auto;
  border: thin solid $border;
  border-radius: 4px;
  padding: 8px 12px 12px;
  background-color: $white;
  box-shadow: 0 4px 10px 0 transparentize(#000000, 0.8);

  &.isCompany {
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 8px 8px 0 0;
    margin-bottom: 0;
  }

  @media (max-width: 1600px) {
    max-width: unset;
  }

  &__wrapper {
    width: 100%;
    margin-bottom: 40px;
  }
  &__title {
    @include text--h1;
    padding-bottom: 2px;
    margin-bottom: 8px;
    &.isCompany {
      margin-top: 40px;
      margin-bottom: 0;
    }
  }
  &__empty {
    @include text--h2;
    color: $text--light;
    margin: 8px 0;
  }
  &__subquestion {
    color: $text--dark;
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    .myBtn {
      color: $text--dark-1;
      display: inline-flex;
      font-size: inherit;
      &:hover {
        color: $new-primary--light;
      }
    }
  }
  &__info {
    color: $mint;
    margin-right: 4px;
  }
  &__header {
    width: 100%;
    padding: 4px 4px 6px 0;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: $text--light;
    &.first {
      grid-column: 1;
    }
  }
  &__item {
    padding: 12px 8px 12px 0;
    margin: 0;
    color: $text--dark;
    font-size: 14px;
    font-weight: normal;
    border-top: thin solid $border;
    max-width: 400px;
    min-width: 120px;
    word-break: break-word;
    &.first {
      grid-column: 1;
      cursor: pointer;
      min-width: 140px;
      svg {
        color: $text--dark-1;
        margin-right: 4px;
      }
      &:hover {
        color: $link--light;
        text-decoration: underline;
        svg {
          color: $link--light;
        }
      }
    }
    &--locked {
      pointer-events: none;
    }
    .myBtn--link {
      padding-left: 0;
      color: $text--dark-1;
      svg {
        margin-right: 4px;
      }
      &:hover {
        color: $new-primary--light;
      }
    }
    &.is-company {
      border-top: none;
      padding: 4px 8px 4px 0;
      .dataTable__header {
        color: $text--dark;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  &__preview {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__add {
    justify-content: flex-start;
    grid-column-start: 1;
    grid-column-end: 3;
    width: fit-content;
    margin-top: 8px;
    font-size: 12px;
    color: $text--dark-1;
    svg {
      color: $new-primary;
      margin-right: 4px;
    }
    &:hover {
      color: $new-primary--light;
    }
  }
}
