.company-name__focused {
  display: flex;
  flex: 1;
  :first-child {
    flex: 1.5;
    margin-right: 5px;
  }
  &.hidden {
    display: none;
  }
}

.company-name__unfocused {
  &.hidden {
    display: none;
  }
}

.company-name__wrapper {
  display: flex;
  width: 100%;
  max-width: 600px;
  justify-content: space-between;
  .styled-text-field__container {
    flex: 1;
    margin-right: 20px;
  }
  .input-select__container {
    flex: 0.5;
  }
}
