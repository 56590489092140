@import 'color/colors';

.navlink__tag {
  height: 24px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  margin: 0px 8px 16px 0;
  padding: 0 20px;
  color: #2d363c;
  border-radius: 20px;
  border: 2px solid transparent;
  // box-shadow: 1px 2px 5px $dark-50;
  background-color: #d3d3d3;
  cursor: pointer;
  transition: all 0.2s;
  white-space: nowrap;
  &.selected {
    border-color: $border--button;
    background-color: $border--button;
    color: $white;
    .navlink__tagLength {
      color: $light-95;
    }

    &:hover {
      background-color: $primary--light-1;
      border-color: $primary--light-1;
      color: $white;
      .navlink__tagLength {
        color: $light-95;
      }
    }
    &:active {
      background-color: $light;
      border-color: $light;
      color: #2d363c;
    }
  }
  &:hover {
    border-color: $primary--light;
    background-color: $primary--light;
    color: $white;
    .navlink__tagLength {
      color: $light-95;
    }
    // background-color: $light;
  }
  &:active {
    background-color: $border--button;
    border-color: $border--button;
    color: $white;
    .navlink__tagLength {
      color: $light-95;
    }
  }

  &Length {
    color: $dark-60;
  }
}

.navlink__tags {
  margin: 20px auto 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  p {
    color: #848484;
    margin: 0 4px 16px 0;
    font-size: 12px;
    font-weight: bold;
  }
}

.navlink__filterClear {
  align-self: center;
  margin-left: 8px;
  margin-bottom: 16px;
}
